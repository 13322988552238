import React from 'react';

export const CloseIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21.0947 6.27405C21.7293 5.63945 21.7293 4.61055 21.0947 3.97595C20.4601 3.34135 19.4312 3.34135 18.7966 3.97595L12.2853 10.4872L5.77407 3.97595C5.13947 3.34135 4.11057 3.34135 3.47597 3.97595C2.84137 4.61055 2.84137 5.63945 3.47597 6.27405L9.98724 12.7853L3.47595 19.2966C2.84135 19.9312 2.84135 20.9601 3.47595 21.5947C4.11055 22.2293 5.13945 22.2293 5.77405 21.5947L12.2853 15.0834L18.7966 21.5947C19.4312 22.2293 20.4601 22.2293 21.0947 21.5947C21.7293 20.9601 21.7293 19.9312 21.0947 19.2966L14.5834 12.7853L21.0947 6.27405Z"
      fill="currentColor"
    />
  </svg>
);
