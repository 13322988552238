import { Locales, defaultLocale } from 'shared';

import { en } from 'src/i18n/dictionaries/en';
import { events } from 'src/i18n/dictionaries/en/events';
import { ru } from 'src/i18n/dictionaries/ru';

export type Dictionary = typeof en;
export type DictionaryKey = keyof Dictionary;
export type Events = keyof typeof events;

/**
 * As keys are used BCP 47 locale identifiers
 * (see ECMAScript Intl standard)
 */
export const locales: Record<
  Locales,
  { title: string; dictionary: Dictionary }
> = {
  en: {
    title: 'English',
    dictionary: en,
  },
  ru: {
    title: 'Русский',
    dictionary: ru,
  },
} as const;

export const defaultDictionary = locales[defaultLocale].dictionary;
