import {
  AreaChartOutlined,
  BookOutlined,
  BugOutlined,
  EditOutlined,
  FormOutlined,
  GlobalOutlined,
  LockOutlined,
  LoginOutlined,
  MailOutlined,
  NotificationOutlined,
  PhoneOutlined,
  RobotOutlined,
  SafetyOutlined,
  TableOutlined,
  UnlockOutlined,
  UserDeleteOutlined,
  WalletOutlined,
  WhatsAppOutlined,
} from '@ant-design/icons';
import React, { lazy } from 'react';

import { FeScheme, MODALS, PAGES } from 'shared';

import { EnumsRole } from 'src/_generated';
import { AccountIcon } from 'src/components/atoms/AccountIcon';
import { AdministrationIcon } from 'src/components/atoms/AdministrationIcon';
import { SizeProp } from 'src/components/atoms/Drawer';
import { MainPanelIcon } from 'src/components/atoms/MainPanelIcon';
import { MarketplaceIcon } from 'src/components/atoms/MarketplaceIcon';
import { DictionaryKey } from 'src/i18n';

export type Roles = 'guest' | EnumsRole;

export const pagesRedirect: Record<Roles, FeScheme> = {
  guest: [{ scheme: PAGES.ROOT }, { scheme: MODALS.SIGN_IN_EMAIL }],
  user: { scheme: PAGES.APP },
  support: { scheme: PAGES.APP },
};

export type PageRoute = {
  access: 'all' | Roles[];
  title: DictionaryKey;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
  icon?: React.FC;
};

export type ModalRoute = PageRoute & { size: SizeProp };

export const pages: Record<PAGES, PageRoute> = {
  [PAGES.ROOT]: {
    access: 'all',
    title: 'components.routes.pages.Root.title',
    component: lazy(() => import('src/components/routes/pages/Root')),
  },

  [PAGES.APP]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.App.title',
    icon: MainPanelIcon,
    component: lazy(() => import('src/components/routes/pages/App')),
  },
  [PAGES.CABINET]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.Cabinet.title',
    icon: AccountIcon,
    component: lazy(() => import('src/components/routes/pages/Cabinet')),
  },
  [PAGES.CABINET_EXCHANGES]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.CabinetExchanges.title',
    icon: WalletOutlined,
    component: lazy(
      () => import('src/components/routes/pages/CabinetExchanges')
    ),
  },
  [PAGES.CABINET_2FA]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.Cabinet2FA.title',
    icon: SafetyOutlined,
    component: lazy(() => import('src/components/routes/pages/Cabinet2FA')),
  },
  [PAGES.CABINET_NOTIFICATIONS]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.CabinetNotifications.title',
    icon: NotificationOutlined,
    component: lazy(
      () => import('src/components/routes/pages/CabinetNotifications')
    ),
  },
  [PAGES.MARKETPLACE]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.Marketplace.title',
    icon: MarketplaceIcon,
    component: lazy(() => import('src/components/routes/pages/Marketplace')),
  },
  [PAGES.MARKETPLACE_SELL]: {
    access: ['user', 'support'],
    title: 'components.routes.pages.Marketplace.title',
    icon: TableOutlined,
    component: lazy(() => import('src/components/routes/pages/Marketplace')),
  },
  [PAGES.ADMIN]: {
    access: ['support'],
    title: 'components.routes.pages.Admin.title',
    icon: AdministrationIcon,
    component: lazy(() => import('src/components/routes/pages/Admin')),
  },
  [PAGES.ADMIN_WIKI]: {
    access: ['support'],
    title: 'components.routes.pages.AdminWiki.title',
    icon: BookOutlined,
    component: lazy(() => import('src/components/routes/pages/AdminWiki')),
  },
  [PAGES.ADMIN_SUPPORT]: {
    access: ['support'],
    icon: BugOutlined,
    title: 'components.routes.pages.AdminSupport.title',
    component: lazy(() => import('src/components/routes/pages/AdminSupport')),
  },
  [PAGES.ADMIN_SUPPORT_MESSAGE]: {
    access: ['support'],
    icon: BugOutlined,
    title: 'components.routes.pages.AdminSupportMessage.title',
    component: lazy(
      () => import('src/components/routes/pages/AdminSupportMessage')
    ),
  },
  [PAGES.CONFIRM_EMAIL]: {
    access: 'all',
    title: 'components.routes.pages.ConfirmEmail.title',
    component: lazy(() => import('src/components/routes/pages/ConfirmEmail')),
  },
  // This item must be the last in the list
  [PAGES.ERROR_404]: {
    access: 'all',
    title: 'components.routes.pages.Error404.title',
    component: lazy(() => import('src/components/routes/pages/Error404')),
  },
};

export const modals: Record<MODALS, ModalRoute> = {
  [MODALS.COOKIES]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.Cookies.title',
    component: lazy(() => import('src/components/routes/modals/Cookies')),
  },
  [MODALS.CONTACTS]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.Contacts.title',
    component: lazy(() => import('src/components/routes/modals/Contacts')),
  },
  [MODALS.PRIVACY]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.Privacy.title',
    component: lazy(() => import('src/components/routes/modals/Privacy')),
  },
  [MODALS.ABOUT_US]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.AboutUs.title',
    component: lazy(() => import('src/components/routes/modals/AboutUs')),
  },
  [MODALS.NOTIFICATIONS]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.Notifications.title',
    component: lazy(() => import('src/components/routes/modals/Notifications')),
  },
  [MODALS.DOCS_ARTICLE]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.DocsArticle.title',
    component: lazy(() => import('src/components/routes/modals/DocsArticle')),
  },
  [MODALS.DOCS]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.Docs.title',
    component: lazy(() => import('src/components/routes/modals/Docs')),
  },
  [MODALS.TERMS]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.Terms.title',
    component: lazy(() => import('src/components/routes/modals/Terms')),
  },
  [MODALS.SUPPORT]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.Support.title',
    component: lazy(() => import('src/components/routes/modals/Support')),
  },
  [MODALS.SUPPORT_MESSAGE]: {
    access: ['support'],
    size: 'default',
    title: 'components.routes.modals.SupportMessage.title',
    component: lazy(
      () => import('src/components/routes/modals/SupportMessage')
    ),
  },
  [MODALS.EXCHANGE]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.Exchanges.title',
    component: lazy(() => import('src/components/routes/modals/Exchanges')),
  },
  [MODALS.EXCHANGE_EDIT]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.Exchanges.title',
    component: lazy(() => import('src/components/routes/modals/Exchanges')),
  },
  [MODALS.EXCHANGE_CONNECT]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.ExchangeConnect.title',
    component: lazy(
      () => import('src/components/routes/modals/ExchangeConnect')
    ),
  },
  [MODALS.CONTACTS_EMAIL]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.ContactsEmail.title',
    component: lazy(() => import('src/components/routes/modals/ContactsEmail')),
    icon: MailOutlined,
  },
  [MODALS.CONTACTS_PHONE]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.ContactsPhone.title',
    component: lazy(() => import('src/components/routes/modals/ContactsPhone')),
    icon: PhoneOutlined,
  },
  [MODALS.CONTACTS_MESSENGERS]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.ContactsMessengers.title',
    component: lazy(
      () => import('src/components/routes/modals/ContactsMessengers')
    ),
    icon: WhatsAppOutlined,
  },
  [MODALS.CONTACTS_PASSWORD]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.ContactsPassword.title',
    component: lazy(
      () => import('src/components/routes/modals/ContactsPassword')
    ),
    icon: LockOutlined,
  },
  [MODALS.CABINET_DELETE]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.CabinetDelete.title',
    component: lazy(() => import('src/components/routes/modals/CabinetDelete')),
    icon: UserDeleteOutlined,
  },
  [MODALS.LANGUAGE]: {
    access: 'all',
    title: 'components.routes.modals.Language.title',
    size: 'default',
    component: lazy(() => import('src/components/routes/modals/Language')),
    icon: GlobalOutlined,
  },
  [MODALS.FORGOT_PASSWORD]: {
    access: ['guest'],
    size: 'default',
    title: 'components.routes.modals.ForgotPassword.title',
    component: lazy(
      () => import('src/components/routes/modals/ForgotPassword')
    ),
    icon: UnlockOutlined,
  },
  [MODALS.FORGOT_ME]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.ForgotMe.title',
    component: lazy(() => import('src/components/routes/modals/ForgotMe')),
  },
  [MODALS.SIGN_IN_EMAIL]: {
    access: ['guest'],
    size: 'default',
    title: 'components.routes.modals.SignInEmail.title',
    component: lazy(() => import('src/components/routes/modals/SignInEmail')),
    icon: LoginOutlined,
  },
  [MODALS.SIGN_UP_EMAIL]: {
    access: ['guest'],
    size: 'default',
    title: 'components.routes.modals.SignUpEmail.title',
    component: lazy(() => import('src/components/routes/modals/SignUpEmail')),
    icon: MailOutlined,
  },
  [MODALS.SIGN_UP_PHONE]: {
    access: ['guest'],
    size: 'default',
    title: 'components.routes.modals.VerifyPhoneNumber.title',
    component: lazy(
      () => import('src/components/routes/modals/VerifyPhoneNumber')
    ),
    icon: PhoneOutlined,
  },
  [MODALS.FORGOT_PASSWORD_CHANGE]: {
    access: ['guest'],
    size: 'default',
    title: 'components.routes.modals.ForgotPasswordChange.title',
    component: lazy(
      () => import('src/components/routes/modals/ForgotPasswordChange')
    ),
  },
  [MODALS.CABINET_SIGN_OUT]: {
    access: 'all',
    size: 'default',
    title: 'components.routes.modals.CabinetSignOut.title',
    icon: BookOutlined,
    component: lazy(
      () => import('src/components/routes/modals/CabinetSignOut')
    ),
  },
  [MODALS.MARKETPLACE_BOT_EDIT]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.MarketplaceBotEdit.title',
    icon: EditOutlined,
    component: lazy(
      () => import('src/components/routes/modals/MarketplaceBotEdit')
    ),
  },
  [MODALS.MARKETPLACE_BOT]: {
    access: ['user', 'support'],
    size: 'large',
    title: 'components.routes.modals.MarketplaceBot.title',
    icon: RobotOutlined,
    component: lazy(
      () => import('src/components/routes/modals/MarketplaceBot')
    ),
  },
  [MODALS.MARKETPLACE_ADD]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.MarketplaceAdd.title',
    icon: FormOutlined,
    component: lazy(
      () => import('src/components/routes/modals/MarketplaceAdd')
    ),
  },
  [MODALS.MARKETPLACE_BOT_STATISTIC]: {
    access: ['user', 'support'],
    size: 'large',
    title: 'components.routes.modals.MarketplaceBotStatistics.title',
    icon: AreaChartOutlined,
    component: lazy(
      () => import('src/components/routes/modals/MarketplaceBotStatistics')
    ),
  },
  [MODALS.ADMIN_WIKI_ADD]: {
    access: ['support'],
    size: 'medium',
    title: 'components.routes.pages.AdminWikiAdd.title',
    icon: BookOutlined,
    component: lazy(() => import('src/components/routes/modals/AdminWikiAdd')),
  },
  [MODALS.ADMIN_WIKI_ARTICLE]: {
    access: ['support'],
    size: 'medium',
    title: 'components.routes.modals.AdminWikiArticle.title',
    icon: BookOutlined,
    component: lazy(
      () => import('src/components/routes/modals/AdminWikiArticle')
    ),
  },
  [MODALS.ADMIN_WIKI_ARTICLE_EDIT]: {
    access: ['support'],
    size: 'medium',
    title: 'components.routes.modals.AdminWikiEdit.title',
    icon: BookOutlined,
    component: lazy(() => import('src/components/routes/modals/AdminWikiEdit')),
  },
  [MODALS.ADD_WALLET]: {
    access: ['user', 'support'],
    size: 'default',
    title: 'components.routes.modals.AddWallet.title',
    icon: BookOutlined,
    component: lazy(() => import('src/components/routes/modals/AddWallet')),
  },
};
