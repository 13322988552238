import { useQuery, useQueryClient } from '@tanstack/react-query';

import { api } from 'src/fetcher/api';
import { MODELS, store } from 'src/fetcher/store';

export const useUser = (enabled?: boolean) => {
  const queryClient = useQueryClient();

  return useQuery([MODELS.PROFILE], api['/profile'].GET, {
    enabled,
    onSuccess: (data) => store.profile(queryClient, data),
  });
};
