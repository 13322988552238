// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/en.po' and run '.po' extractor
export const events = {
  'error.addWallet.required': 'The field is required',

  'error.apiKey.min': 'API key is too short',
  'error.apiKey.required': 'API key is required',

  'error.code.min': 'Code cannot be shorter than 4 characters',
  'error.code.required': 'Your code is required',

  'error.confirmationCode.expired': 'Confirmation code is expired',
  'error.confirmationCode.notValid': 'Confirmation code is not valid',

  'error.contact.notLinked': 'The contact is not linked to account',

  'error.createArticle.required': 'The field is required',

  'error.createBot.required': 'The field is required',

  'error.email.alreadyExists': 'User with this email already exists',
  'error.email.format': 'You have entered an invalid email',
  'error.email.notLinked': 'The email is not linked to account',
  'error.email.notValid': 'Invalid email',
  'error.email.required': 'Your email is required',
  'error.email.send': 'Failed to send an email',

  'error.emailOrPassword.notValid': 'Invalid email or password',

  'error.endpoint.botIsBought': 'Bot is already bought',
  'error.endpoint.botIsNotExist': 'Bot is not exist',
  'error.endpoint.botStatusError':
    'It is not possible to set "error" bot status',
  'error.endpoint.equalCurrency':
    'It is not possible to set equivalent currencies',
  'error.endpoint.impossibleChangeStatus':
    'It is not possible to change the status of an active bot',
  'error.endpoint.impossibleUpdateErrorBot':
    'It is not possible to change the bot with an error. Turn off the bot and repeat again',
  'error.endpoint.invalidDate': 'Invalid date',
  'error.endpoint.invalidId': 'Invalid id',
  'error.endpoint.invalidPhone': 'Invalid phone number',
  'error.endpoint.messengerLinked':
    'This messenger is already linked to the account',
  'error.endpoint.notValid': 'Bad endpoint',
  'error.endpoint.pairNotExist': 'The trading pair does not exist',
  'error.endpoint.stopLossOverlap':
    'The sum of the stop loss and overlap is more than 99',
  'error.endpoint.wikiArticleIsNotExist': 'Wiki article is not exist',

  'error.exhange.linked': 'The exchange is already linked',
  'error.exhange.notLinked': 'The exchange is not linked',

  'error.file.download': 'Error downloading the file',

  'error.login.accountHaventRegisteredEmail':
    'The selected account does not have a linked email account',
  'error.login.accountHaventRegisteredInfo':
    "Your account haven't registered information",
  'error.login.accountInDeletionProcess':
    'The account with this email is in the process of being deleted.',
  'error.login.accountIsService': 'The selected account is service account',
  'error.login.accountPartialFilled':
    'The selected account is partially filled',
  'error.login.alreadyLoggedIn': 'You are already logged in',
  'error.login.need': 'You need to log in',

  'error.message.notFound': 'The support message not found',
  'error.message.required': 'This field is required',

  'error.password.min': 'Password cannot be shorter than 8 characters',
  'error.password.notValid': 'Incorrect password',
  'error.password.required': 'Your password is required',

  'error.passwordNew.notMatch': 'Passwords must match',
  'error.passwordNew.required': 'Enter a new password',

  'error.permissionDenied': 'Permission denied',

  'error.phone.notLinked': 'The phone is not linked',

  'error.price.lessOrEqual0': 'Values cannot be less than or equal to zero',

  'error.regionNotSupported': 'The region is not supported',

  'error.secretKey.required': 'Secret part of API key is required',

  'error.terms.needToAccept': 'You need to accept the terms of service',

  'error.text.required': 'This field is required.',

  'error.theme.required': 'This field is required',

  'error.topUpAmount.min': 'Quantity less than minimum value',

  'error.topUpBalance.amountShouldMoreZero': 'The amount should be more zero',

  'error.user.notFound': 'User not found',

  'success.contacts.sent': 'Your request has been successfully sent.',

  'success.email.sent':
    'A confirmation email has been sent to the email address provided.',

  'success.exchange.connected': 'Exchange settings saved',
  'success.exchange.disconnected': 'Exchange settings removed',
  'success.exchange.updated': 'Exchange settings updated',

  'success.password.changed': 'Your password has been successfully changed',

  'success.phone.sent':
    'A confirmation code has been sent to the specified phone number',

  'success.support.sent':
    'Your request has been successfully sent. We will contact you very soon!',

  'success.supportReply.sent': 'Your answer has been successfully sent',
};
