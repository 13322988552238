import { makeApi } from 'types-to-fetchers';

import { ERRORS } from 'shared';

import { API, SimpleError } from 'src/_generated';
import { API_HOST, PUBLIC_ENV } from 'src/constants/env';
import { DictionaryKey } from 'src/i18n';

export const makeAPIUrl = (protocol: 'https' | 'wss') => {
  const API_PROTOCOL =
    PUBLIC_ENV === 'local' ? protocol.slice(0, -1) : protocol;

  return `${API_PROTOCOL}://${API_HOST}`;
};

export const errorToDictionaryKey: Record<ERRORS, DictionaryKey> = {
  [ERRORS.ACCOUNT_HAVENT_REGISTERED_INFO]:
    'error.login.accountHaventRegisteredInfo',
  [ERRORS.AMOUNT_SHOULD_BE_MORE_ZERO]:
    'error.topUpBalance.amountShouldMoreZero',
  [ERRORS.ACCOUNT_IN_DELETION_PROCESS]: 'error.login.accountInDeletionProcess',
  [ERRORS.ACCOUNT_HAVENT_REGISTERED_EMAIL]:
    'error.login.accountHaventRegisteredEmail',
  [ERRORS.ACCOUNT_PARTIAL_FILLED]: 'error.login.accountPartialFilled',
  [ERRORS.ACCOUNT_IS_SERVICE]: 'error.login.accountIsService',
  [ERRORS.ALREADY_LOGGED_IN]: 'error.login.alreadyLoggedIn',
  [ERRORS.BAD_ENDPOINT]: 'error.endpoint.notValid',
  [ERRORS.BOT_IS_BOUGHT]: 'error.endpoint.botIsBought',
  [ERRORS.BOT_IS_NOT_EXIST]: 'error.endpoint.botIsNotExist',
  [ERRORS.BOT_STAUS_ERROR]: 'error.endpoint.botStatusError',
  [ERRORS.CONFIRMATION_CODE_EXPIRED]: 'error.confirmationCode.expired',
  [ERRORS.CONFIRMATION_CODE_NOT_VALID]: 'error.confirmationCode.notValid',
  [ERRORS.CONTACT_NOT_LINKED]: 'error.contact.notLinked',
  [ERRORS.EMAIL_ALREADY_EXISTS]: 'error.email.alreadyExists',
  [ERRORS.EMAIL_NOT_LINKED]: 'error.email.notLinked',
  [ERRORS.EQUAL_CURRENCY]: 'error.endpoint.equalCurrency',
  [ERRORS.ERROR_FILE_DOWNLOAD]: 'error.file.download',
  [ERRORS.ERROR_SEND_EMAIL]: 'error.email.send',
  [ERRORS.EXCHANGE_ALREADY_LINKED]: 'error.exhange.linked',
  [ERRORS.EXCHANGE_NOT_LINKED]: 'error.exhange.notLinked',
  [ERRORS.EXTENSION_NOT_EXIST]: 'error.endpoint.extensionNotExist',
  [ERRORS.FILE_NOT_EXIST]: 'error.endpoint.fileNotExist',
  [ERRORS.FILE_SIZE_EXCEEDED]: 'error.endpoint.fileSizeExceeded',
  [ERRORS.IMPOSSIBLE_CHANGE_STATUS]: 'error.endpoint.impossibleChangeStatus',
  [ERRORS.IMPOSSIBLE_UPDATE_ERROR_BOT]:
    'error.endpoint.impossibleUpdateErrorBot',
  [ERRORS.INCORRECT_PASSWORD]: 'error.password.notValid',
  [ERRORS.INVALID_DATE]: 'error.endpoint.invalidDate',
  [ERRORS.INVALID_EMAIL_OR_PASSWORD]: 'error.emailOrPassword.notValid',
  [ERRORS.INVALID_EMAIL]: 'error.email.notValid',
  [ERRORS.INVALID_ID]: 'error.endpoint.invalidId',
  [ERRORS.INVALID_PHONE]: 'error.endpoint.invalidPhone',
  [ERRORS.MESSAGE_NOT_FOUND]: 'error.message.notFound',
  [ERRORS.MESSENGER_LINKED]: 'error.endpoint.messengerLinked',
  [ERRORS.MESSENGER_NOT_LINKED]: 'error.apiKey.min',
  [ERRORS.MINIMAL_TOP_UP_AMOUNT]: 'error.topUpAmount.min',
  [ERRORS.NEED_TO_ACCEPT_TERMS]: 'error.terms.needToAccept',
  [ERRORS.NEED_TO_ADD_EMAIL]: 'error.endpoint.needAddEmail',
  [ERRORS.NEED_TO_LOGIN]: 'error.login.need',
  [ERRORS.PAIR_NOT_EXIST]: 'error.endpoint.pairNotExist',
  [ERRORS.PERMISSION_DENIED]: 'error.permissionDenied',
  [ERRORS.PHONE_NOT_LINKED]: 'error.phone.notLinked',
  [ERRORS.REGION_NOT_SUPPORTED]: 'error.regionNotSupported',
  [ERRORS.SEND_SMS_ERROR]: 'error.regionNotSupported',
  [ERRORS.STOP_LOSS_AND_OVERLAP]: 'error.endpoint.stopLossOverlap',
  [ERRORS.UNSUPPORTED_FILE_TYPE]: 'error.endpoint.unsupportedFile',
  [ERRORS.USER_NOT_FOUND]: 'error.user.notFound',
  [ERRORS.VALUES_LESS_OR_EQUAL_ZERO]: 'error.price.lessOrEqual0',
  [ERRORS.WIKI_ARTICLE_NOT_EXIST]: 'error.endpoint.wikiArticleIsNotExist',
};

type Api = Omit<
  API,
  | '*'
  | '/swagger'
  | '/swagger/'
  | '/swagger/*'
  | '/swagger/initOAuth'
  | '/swagger/json'
  | '/swagger/static/*'
  | '/swagger/uiConfig'
  | '/swagger/yaml'
>;

export const api = makeApi<Api, SimpleError>(
  {
    '/': ['GET'],
    '/balance/top-up': ['GET', 'POST'],
    '/bot': ['GET', 'POST'],
    '/bot/:id': ['GET', 'PUT'],
    '/bot/buy/:id': ['POST'],
    '/bot/my': ['GET'],
    '/bot/finance': ['GET'],
    '/bot/statistic/status': ['GET'],
    '/bot/:id/orders': ['GET'],
    '/profile/notifications/:contact': ['PUT'],
    '/confirm/email': ['POST'],
    '/confirm/phone': ['POST'],
    '/exchange-info/:name': ['GET'],
    '/log': ['GET'],
    '/password/change': ['POST'],
    '/password/forgot': ['POST'],
    '/password/forgot/change': ['POST'],
    '/profile': ['GET', 'PUT'],
    '/profile/:id': ['PUT'],
    '/profile/email': ['PUT'],
    '/profile/exchange': ['POST'],
    '/profile/messenger/vk': ['POST', 'DELETE'],
    '/profile/messenger/vk/webhook': ['POST'],
    '/profile/messenger/telegram': ['POST', 'DELETE'],
    '/profile/messenger/whatsapp': ['POST', 'DELETE'],
    '/profile/messenger/whatsapp/webhook': ['GET', 'POST'],
    '/profile/exchange/:name': ['PUT', 'DELETE'],
    '/profile/phone': ['PUT'],
    '/sign-in/email': ['POST'],
    '/sign-out': ['POST'],
    '/sign-up/email': ['POST'],
    '/sign-up/google': ['POST'],
    '/sign-up/phone': ['POST'],
    '/sign-up/phone/code': ['POST'],
    '/sign-up/vk': ['POST'],
    '/support': ['GET', 'POST'],
    '/support/:id': ['GET'],
    '/support/reply': ['POST'],
    '/support/file/image': ['POST'],
    '/static_uploaded/*': ['GET'],
    '/wiki': ['GET', 'POST'],
    '/wiki/:id': ['GET', 'PUT', 'DELETE'],
  },
  {
    baseURL: makeAPIUrl('https'),
  }
);
