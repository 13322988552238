import React from 'react';

export const AdministrationIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12.833 4.667H9.1c-1.96 0-2.94 0-3.689.381a3.5 3.5 0 0 0-1.53 1.53C3.5 7.326 3.5 8.306 3.5 10.267V18.9c0 1.96 0 2.94.381 3.689a3.5 3.5 0 0 0 1.53 1.53c.749.381 1.729.381 3.689.381h8.633c1.96 0 2.94 0 3.69-.381a3.5 3.5 0 0 0 1.529-1.53c.381-.749.381-1.729.381-3.689v-3.733m-8.166 4.666h-7m9.333-4.666H8.167M23.475 4.525a3.5 3.5 0 1 1-4.95 4.95 3.5 3.5 0 0 1 4.95-4.95Z"
    />
  </svg>
);
