export enum ERROR_CODE {
  OK = 200,
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  LEGALS_REASON = 451,
  INTERNAL_SERVER = 500,
}

export enum ERRORS {
  ACCOUNT_HAVENT_REGISTERED_INFO = "Account haven't registered info",
  AMOUNT_SHOULD_BE_MORE_ZERO = 'Amount is not valid. Should be number more than zero',
  ACCOUNT_HAVENT_REGISTERED_EMAIL = 'The selected account does not have a linked email account',
  ACCOUNT_PARTIAL_FILLED = 'The selected account is partially filled',
  ACCOUNT_IS_SERVICE = 'The selected account is service account',
  ACCOUNT_IN_DELETION_PROCESS = 'Account in the process of deletion',
  ALREADY_LOGGED_IN = 'You are already logged in',
  BAD_ENDPOINT = 'Bad endpoint',
  BOT_IS_BOUGHT = 'Bot is already bought',
  BOT_IS_NOT_EXIST = 'Bot is not exist',
  BOT_STAUS_ERROR = 'It is not possible to set "error" bot status',
  CONFIRMATION_CODE_EXPIRED = 'Confirmation code is expired',
  CONFIRMATION_CODE_NOT_VALID = 'Confirmation code is not valid',
  CONTACT_NOT_LINKED = 'The contact is not linked to account',
  EMAIL_ALREADY_EXISTS = 'User with this email already exists',
  EMAIL_NOT_LINKED = 'The email is not linked to account',
  EQUAL_CURRENCY = 'It is not possible to set equivalent currencies',
  ERROR_FILE_DOWNLOAD = 'Error downloading the file',
  ERROR_SEND_EMAIL = 'An error occurred while sending a email message',
  EXCHANGE_ALREADY_LINKED = 'The exchange is already linked',
  EXCHANGE_NOT_LINKED = 'The exchange is not linked',
  EXTENSION_NOT_EXIST = 'The extension not exist',
  FILE_NOT_EXIST = 'File not exist',
  FILE_SIZE_EXCEEDED = 'File size exceeded',
  IMPOSSIBLE_CHANGE_STATUS = 'It is not possible to change the status of an active bot',
  IMPOSSIBLE_UPDATE_ERROR_BOT = 'It is not possible to change the bot with an error. Turn off the bot and repeat again',
  INCORRECT_PASSWORD = 'Incorrect password',
  INVALID_DATE = 'Invalid date',
  INVALID_EMAIL = 'Invalid email',
  INVALID_EMAIL_OR_PASSWORD = 'Invalid email or password',
  INVALID_ID = 'Invalid ID',
  INVALID_PHONE = 'Invalid phone',
  MESSAGE_NOT_FOUND = 'The message not found',
  MESSENGER_LINKED = 'The messenger is already linked to another account',
  MESSENGER_NOT_LINKED = 'The messenger is not linked to account',
  MINIMAL_TOP_UP_AMOUNT = 'Quantity less than minimum value',
  NEED_TO_ACCEPT_TERMS = 'You need to accept the terms of service',
  NEED_TO_ADD_EMAIL = 'You need to add email',
  NEED_TO_LOGIN = 'You need to log in',
  PAIR_NOT_EXIST = 'The trading pair does not exist',
  PERMISSION_DENIED = 'Permission denied',
  PHONE_NOT_LINKED = 'The phone number is not linked to account',
  REGION_NOT_SUPPORTED = 'The region is not supported',
  SEND_SMS_ERROR = 'An error occurred while sending a text message',
  STOP_LOSS_AND_OVERLAP = 'The sum of the stop loss and overlap is more than 99',
  UNSUPPORTED_FILE_TYPE = 'File type not supported',
  USER_NOT_FOUND = 'User not found',
  VALUES_LESS_OR_EQUAL_ZERO = 'Values cannot be less than or equal to zero',
  WIKI_ARTICLE_NOT_EXIST = 'Wiki article is not exist',
}
