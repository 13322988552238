import React, { ReactNode } from 'react';

import { RollbarProvider } from 'src/components/providers/RollbarProvider';
import { QueryProvider } from 'src/fetcher/QueryProvider';
import { IntlProvider } from 'src/i18n/IntlProvider';

import 'antd/dist/reset.css';

import 'src/styles/articles.css';
import 'src/styles/global.css';
import 'src/styles/variables.css';

type Props = {
  children: ReactNode;
};

export const Provider: React.FC<Props> = ({ children }) => (
  <RollbarProvider>
    <QueryProvider>
      <IntlProvider>{children}</IntlProvider>
    </QueryProvider>
  </RollbarProvider>
);
