import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Router as BrowserRouter } from 'react-router-dom';

import { Preloader } from 'src/components/atoms/Preloader';
import { api } from 'src/fetcher/api';
import { MODELS } from 'src/fetcher/store';
import { useUser } from 'src/hooks/useUser';
import { ModalsSwitch, PagesSwitch } from 'src/navigation/Switch';
import { history } from 'src/navigation/store';
import { useHistory } from 'src/navigation/useHistory';

export const Router: React.FC = () => {
  const { location, action } = useHistory();

  const rootGet = useQuery([MODELS.ROOT], api['/'].GET);

  const isLoggedIn = rootGet.data?.isAuthenticated ?? false;

  const profileGet = useUser(isLoggedIn);

  if (rootGet.isLoading || (isLoggedIn && profileGet.isLoading)) {
    return <Preloader fullScreen />;
  }

  const role = profileGet.data?.role;

  const mode = isLoggedIn && role ? role : 'guest';

  return (
    <BrowserRouter
      location={location}
      navigationType={action}
      navigator={history}
    >
      <PagesSwitch mode={mode} />
      <ModalsSwitch mode={mode} />
    </BrowserRouter>
  );
};
