// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/ru.po' and run '.po' extractor
export const base = {
  'components.atoms.Copyright.text': '©{currentYear}, 7SenseTrade',

  'components.components.molecules.QuickSingnIn.modalInactiveExchangeButton':
    'Перейти по ссылке',
  'components.components.molecules.QuickSingnIn.modalInactiveExchangeSubTitle':
    'Для завершения подключения уведомлений из VK перейдите по ссылке и отправьте боту следующий код:{code}',
  'components.components.molecules.QuickSingnIn.modalInactiveExchangeTitle':
    'Необходимо завершить подключения уведомлений ВК',

  'components.molecules.Footer.aboutUs': 'О нас',
  'components.molecules.Footer.contacts': 'Контакты',
  'components.molecules.Footer.cookies': 'Политика использования файлов cookie',
  'components.molecules.Footer.docs': 'Справочный раздел',
  'components.molecules.Footer.forUsers': 'Пользователям',
  'components.molecules.Footer.policies': 'Политика',
  'components.molecules.Footer.privacy': 'Политика конфиденциальности',
  'components.molecules.Footer.service': 'Условия предоставления услуг',
  'components.molecules.Footer.socialMedia': 'Соцсети',

  'components.molecules.List.noData': 'Нет данных',

  'components.molecules.Messengers.title': 'Адресация уведомлений',

  'components.molecules.NoLogs.title': 'Логов еще нет',

  'components.molecules.PasswordForgotSent.button': 'Ввести E-mail заново',
  'components.molecules.PasswordForgotSent.subTitle':
    'Ссылку для восстановления пароля была отправлена на указанную Вами почту {email}',
  'components.molecules.PasswordForgotSent.title': 'Проверьте свою почту!',

  'components.molecules.QuickSignIn.text': 'Быстрый вход',

  'components.molecules.Table.noData': 'Нет данных',

  'components.molecules.VkAuth.connectVk': 'Подключить ВКонтакте',

  'components.organisms.AdminTabs.logs.title': 'Просмотр логов',
  'components.organisms.AdminTabs.support.title': 'Техподдержка',
  'components.organisms.AdminTabs.wiki.add': 'Добавить статью',
  'components.organisms.AdminTabs.wiki.title': 'Wiki',

  'components.organisms.ArticleList.problemTitle': 'Сообщить о проблеме',

  'components.organisms.Balance.currency': '{value} USDT',

  'components.organisms.BotCharacteristics.add': 'Создать бота',
  'components.organisms.BotCharacteristics.title': 'Характеристики ботов',

  'components.organisms.BotState.title': 'Состояние ботов',
  'components.organisms.BotState.total': 'Всего',

  'components.organisms.BotTable.algorithm': 'Алгоритм',
  'components.organisms.BotTable.countOrders': 'Сетка ордеров',
  'components.organisms.BotTable.created': 'Создан',
  'components.organisms.BotTable.deposit': 'Депозит',
  'components.organisms.BotTable.errors': 'Ошибки',
  'components.organisms.BotTable.marketplace': 'Биржа',
  'components.organisms.BotTable.modalInactiveButton': 'Отключить',
  'components.organisms.BotTable.modalInactiveSubTitle':
    'Вы действительно хотите отключить бота? Это действие отменит все ордера выбранного бота',
  'components.organisms.BotTable.modalInactiveTitle': 'Отключить бота',
  'components.organisms.BotTable.name': 'Название',
  'components.organisms.BotTable.priceOverlap': 'Перекрытие изменения цены',
  'components.organisms.BotTable.profit': 'Величина профита',
  'components.organisms.BotTable.started': 'Запущен',
  'components.organisms.BotTable.status': 'Статус',
  'components.organisms.BotTable.totalIncome': 'Общий доход',
  'components.organisms.BotTable.tradingPair': 'Торговая пара',

  'components.organisms.BotTabs.edit': 'Редактировать',
  'components.organisms.BotTabs.info': 'Характеристика',
  'components.organisms.BotTabs.statistics': 'Статистика',

  'components.organisms.BotsTable.alertMessage':
    'Не удаляйте ордера на стороне биржи! Если вы хотите удалить автоматически созданные ордера, просто остановите соответствующего бота.',
  'components.organisms.BotsTable.buyButton': 'Купить',
  'components.organisms.BotsTable.disableEdit_tooltip':
    'Редактирование запрещено из-за некорректной работы бота. Отключите бота и повторите попытку.',
  'components.organisms.BotsTable.editButton': 'Редактировать',
  'components.organisms.BotsTable.error_tooltip': 'Ошибок: {number}',
  'components.organisms.BotsTable.makeActive': 'Работает',
  'components.organisms.BotsTable.viewButton': 'Посмотреть статистику',

  'components.organisms.Chat.messagePlaceholder': 'Написать сообщение',
  'components.organisms.Chat.sender': 'Отправитель',
  'components.organisms.Chat.status': 'Статус',
  'components.organisms.Chat.subject': 'Тема сообщения',

  'components.organisms.ChatMessageGroup.attachment': 'Вложение {counter}',

  'components.organisms.ChatsTable.authorEmail': 'Email автора',
  'components.organisms.ChatsTable.created': 'Заявка от',
  'components.organisms.ChatsTable.noData': 'Нет данных',
  'components.organisms.ChatsTable.status': 'Статус',
  'components.organisms.ChatsTable.theme': 'Тема',

  'components.organisms.ExportReports.allTime': 'за всё время',
  'components.organisms.ExportReports.perDay': 'за день',
  'components.organisms.ExportReports.perMonth': 'за месяц',
  'components.organisms.ExportReports.perWeek': 'за неделю',

  'components.organisms.Landing.app': 'Кабинет',
  'components.organisms.Landing.footerBot':
    'Круглосуточный автоматизированный бот для торговли криптовалютой',
  'components.organisms.Landing.footerPresets': 'Готовые пресеты',
  'components.organisms.Landing.footerSettings': 'Гибкие настройки бота',
  'components.organisms.Landing.footerTrade':
    'Одновременная торговля на нескольких биржах',
  'components.organisms.Landing.goToAccountButton': 'Перейти',
  'components.organisms.Landing.heroSubtitle':
    'Лучший помощник для торговли на криптовалютных биржах. Трейдинг еще никогда не был таким легким.',
  'components.organisms.Landing.heroTitle': 'Призван автоматизировать трейдинг',
  'components.organisms.Landing.reasonsAutomationText':
    'Круглосуточная торговля без вашего постоянного участия в процессах',
  'components.organisms.Landing.reasonsAutomationTitle': 'Автоматизация',
  'components.organisms.Landing.reasonsButtonText': 'Начать торговлю',
  'components.organisms.Landing.reasonsFunctionalityText':
    'Множество гибких настроек и готовые пресеты для удобства',
  'components.organisms.Landing.reasonsFunctionalityTitle': 'Функциональность',
  'components.organisms.Landing.reasonsMultitaskingText':
    'Одновременное задействование ботов на нескольких биржах',
  'components.organisms.Landing.reasonsMultitaskingTitle': 'Многозадачность',
  'components.organisms.Landing.reasonsTitle': 'Почему выбирают 7 Sense Trade?',
  'components.organisms.Landing.signIn': 'Войти',
  'components.organisms.Landing.signInButton': 'Вход',
  'components.organisms.Landing.signUp': 'Зарегистрироваться',
  'components.organisms.Landing.signUpButton': 'Регистрация',
  'components.organisms.Landing.social': 'Круглосуточная поддержка',
  'components.organisms.Landing.subtitle':
    'Ваш незаменимый помощник  для лёгкой торговли на лучших  криптовалютных биржах',

  'components.organisms.LogsTable.endpoint': 'Эндпоинт',
  'components.organisms.LogsTable.ip': 'IP',
  'components.organisms.LogsTable.method': 'Метод',
  'components.organisms.LogsTable.request': 'Запрос',
  'components.organisms.LogsTable.tableDate': 'Время и дата',
  'components.organisms.LogsTable.userId': 'ID пользователя',

  'components.organisms.MarketplaceBot.buyButton': 'Купить',
  'components.organisms.MarketplaceBot.disableEdit_tooltip':
    'Редактирование запрещено, потому что статус error, отключите бота и редактирование станет доступно.',
  'components.organisms.MarketplaceBot.editButton': 'Редактировать',
  'components.organisms.MarketplaceBot.statisticsButton': 'Статистика',

  'components.organisms.MarketplaceBotStatistics.created': 'Создан',
  'components.organisms.MarketplaceBotStatistics.cycleCancellation':
    'Причина отмены цикла',
  'components.organisms.MarketplaceBotStatistics.cycleStatus': 'Статус цикла',
  'components.organisms.MarketplaceBotStatistics.deposit': 'Депозит',
  'components.organisms.MarketplaceBotStatistics.exchangeRate': 'Биржевой курс',
  'components.organisms.MarketplaceBotStatistics.mainPair': 'Главная пара',
  'components.organisms.MarketplaceBotStatistics.noData': 'Нет данных',
  'components.organisms.MarketplaceBotStatistics.orderStatus':
    'Состояние ордеров',
  'components.organisms.MarketplaceBotStatistics.period': 'Период',
  'components.organisms.MarketplaceBotStatistics.profit': 'Величина дохода BTC',
  'components.organisms.MarketplaceBotStatistics.secondaryPair':
    'Вторичная пара',
  'components.organisms.MarketplaceBotStatistics.tradingPair': 'Торговая пара',

  'components.organisms.NoBots.button.buy': 'Добавить',
  'components.organisms.NoBots.button.create': 'Создать',
  'components.organisms.NoBots.title': 'Ботов еще нет',

  'components.organisms.NotificationsTable.changeEmail': 'Смена логина',
  'components.organisms.NotificationsTable.changePassword': 'Смена пароля',
  'components.organisms.NotificationsTable.changePhone': 'Смена телефона',
  'components.organisms.NotificationsTable.email': 'E-mail',
  'components.organisms.NotificationsTable.security': 'Безопасность',
  'components.organisms.NotificationsTable.sms': 'SMS',
  'components.organisms.NotificationsTable.subtitle': 'Виды уведомлений',
  'components.organisms.NotificationsTable.system': 'Systems',
  'components.organisms.NotificationsTable.telegram': 'Telegram',
  'components.organisms.NotificationsTable.vk': 'VK',
  'components.organisms.NotificationsTable.whatsApp': 'WhatsApp',

  'components.organisms.ProfitChart.radio.month': 'Месяц',
  'components.organisms.ProfitChart.radio.week': 'Неделя',
  'components.organisms.ProfitChart.radio.year': 'Год',
  'components.organisms.ProfitChart.title': 'Доходы',
  'components.organisms.ProfitChart.total': 'Всего',

  'components.organisms.SignOut.button': 'Выйти',

  'components.organisms.WikiTabs.edit': 'Редактировать',
  'components.organisms.WikiTabs.view': 'Посмотреть',

  'components.organisms.forms.AddWallet.amount': 'Количество',
  'components.organisms.forms.AddWallet.currency': 'Валюта',
  'components.organisms.forms.AddWallet.saveButton': 'Подтвердить',
  'components.organisms.forms.CreateArticleForm.deleteButton': 'Удалить',
  'components.organisms.forms.CreateArticleForm.description':
    'Краткое описание',
  'components.organisms.forms.CreateArticleForm.saveButton': 'Сохранить',
  'components.organisms.forms.CreateArticleForm.text': 'Текст статьи',
  'components.organisms.forms.CreateArticleForm.title': 'Заголовок',
  'components.organisms.forms.CreateBotForm.botName': 'Имя бота',
  'components.organisms.forms.CreateBotForm.countOrders': 'Сетка ордеров',
  'components.organisms.forms.CreateBotForm.deposit': 'Величина депозита',
  'components.organisms.forms.CreateBotForm.exchange': 'Биржа',
  'components.organisms.forms.CreateBotForm.martingale': 'Процент Мартингейла',
  'components.organisms.forms.CreateBotForm.overlap':
    'Перекрытие изм. цены в %',
  'components.organisms.forms.CreateBotForm.saveButton': 'Сохранить',
  'components.organisms.forms.CreateBotForm.status': 'Статус',
  'components.organisms.forms.CreateBotForm.stopLoss': 'Стоп лосс',
  'components.organisms.forms.CreateBotForm.takeProfit': 'Тейк-профит',
  'components.organisms.forms.CreateBotForm.tradingPair': 'Торговая пара',
  'components.organisms.forms.DeleteAccountForm.button': 'Подтвердить',
  'components.organisms.forms.DeleteAccountForm.info':
    'Ваш аккаунт будет безвозвратно удален через 30 дней',
  'components.organisms.forms.DeleteAccountForm.placeholder': 'Введите пароль',
  'components.organisms.forms.EditBotForm.stopLoss': 'Стоп-лосс',
  'components.organisms.forms.EmailChangeForm.button': 'Отправить',
  'components.organisms.forms.EmailChangeForm.email': 'Email',
  'components.organisms.forms.ForgotPasswordChangeForm.button': 'Сохранить',
  'components.organisms.forms.ForgotPasswordChangeForm.newPassword':
    'Новый пароль',
  'components.organisms.forms.ForgotPasswordChangeForm.newPassword2':
    'Новый пароль еще раз',
  'components.organisms.forms.ForgotPasswordForm.description':
    'Мы отправим ссылку для восстановления пароля на указанную Вами почту',
  'components.organisms.forms.MessengerForm.button': 'Подтвердить',
  'components.organisms.forms.MessengerForm.connect': 'Подключить',
  'components.organisms.forms.MessengerForm.disconnect': 'Отключить',
  'components.organisms.forms.MessengerForm.telegram':
    'Введите ваш номер Telegram',
  'components.organisms.forms.MessengerForm.vk': 'Вконтакте',
  'components.organisms.forms.MessengerForm.whatsApp':
    'Введите ваш номер WhatsApp',
  'components.organisms.forms.MessengerForm.whatsapp': 'Вотсапп',
  'components.organisms.forms.PasswordChangeForm.button': 'Отправить',
  'components.organisms.forms.PasswordChangeForm.newPassword': 'Новый пароль',
  'components.organisms.forms.PasswordChangeForm.password': 'Текущий пароль',
  'components.organisms.forms.PasswordForgotForm.button': 'Отправить',
  'components.organisms.forms.PasswordForgotForm.email': 'Email',
  'components.organisms.forms.PhoneChangeForm.button': 'Отправить',
  'components.organisms.forms.PhoneChangeForm.phone': 'Номер телефона',
  'components.organisms.forms.SignInEmailForm.button': 'Войти',
  'components.organisms.forms.SignInEmailForm.email': 'Email',
  'components.organisms.forms.SignInEmailForm.forgotPassword': 'Забыли пароль?',
  'components.organisms.forms.SignInEmailForm.password': 'Пароль',
  'components.organisms.forms.SignUpEmailForm.button': 'Зарегистрироваться',
  'components.organisms.forms.SignUpEmailForm.checkbox':
    'Регистрируясь, вы соглашаетесь с:',
  'components.organisms.forms.SignUpEmailForm.cookies':
    'Политикой использования файлов cookie',
  'components.organisms.forms.SignUpEmailForm.email': 'Email',
  'components.organisms.forms.SignUpEmailForm.password': 'Пароль',
  'components.organisms.forms.SignUpEmailForm.privacy':
    'Политикой конфиденциальности',
  'components.organisms.forms.SignUpEmailForm.service':
    'Условиями предоставления услуг',
  'components.organisms.forms.SignUpEmailForm.signInButton': 'Вход',
  'components.organisms.forms.VerifyPhoneNumberForm.button': 'Отправить',
  'components.organisms.forms.VerifyPhoneNumberForm.code': 'Введите код из SMS',

  'components.routes.modals.AboutUs.title': 'О нас',
  'components.routes.modals.AddWallet.title': 'Онлайн касса',
  'components.routes.modals.AdminWikiAdd.title': 'Добавить статью',
  'components.routes.modals.AdminWikiArticle.title': 'Статьи',
  'components.routes.modals.AdminWikiEdit.title': 'Редактировать статью',
  'components.routes.modals.CabinetDelete.title': 'Удалить аккаунт',
  'components.routes.modals.CabinetSignOut.title': 'Выйти из аккаунта',
  'components.routes.modals.Contacts.title': 'Контакты',
  'components.routes.modals.ContactsEmail.title': 'Изменение email',
  'components.routes.modals.ContactsMessengers.title': 'Изменение мессенджера',
  'components.routes.modals.ContactsPassword.title': 'Изменение пароля',
  'components.routes.modals.ContactsPhone.title': 'Изменение номера телефона',
  'components.routes.modals.Cookies.point1':
    'Веб-сайт 7sensetrade использует файлы cookie, чтобы сделать доступными персонализированные функции и проанализировать использование веб-сайта 7sensetrade. Файл cookie — это небольшой файл, в котором хранится определенная информация об устройстве доступа пользователя (ПК, планшет, смартфон и т. д.). Когда устройство обращается к веб-сайту 7sensetrade, сервер получает файлы cookie. Сервер может оценивать информацию, хранящуюся в файле cookie, с помощью различных методов. Файлы cookie позволяют персонализировать рекламу в соответствии с собранным поведением пользователей и путем сбора статистических данных об использовании веб-сайта. Пользователи могут принимать или отключать файлы cookie в настройках своего браузера. Однако, если пользователь отключит файлы cookie, он или она не сможет использовать все интерактивные функции веб-сайта 7sensetrade.',
  'components.routes.modals.Cookies.point2':
    'Более того, каждая отдельная веб-страница использует файлы журналов; для каждого показа страницы данные доступа сохраняются в журнале сервера. Сохраненные данные содержат следующие детали:',
  'components.routes.modals.Cookies.point2subpoint1':
    'Дата, время, доступ к файлу, статус, запрос, который браузер пользователя отправил на сервер, объем переданных данных и веб-страница, с которой пользователь получил доступ к запрошенной странице (реферер).',
  'components.routes.modals.Cookies.point2subpoint2':
    'Информация о продукте и версии используемого браузера, операционной системе пользователя и стране происхождения пользователя.',
  'components.routes.modals.Cookies.point3':
    '7sensetrade использует данные журнала только в анонимной форме (т.е. без какого-либо распределения или указаний на личность пользователя) для статистического анализа; например, чтобы узнать, в какие дни и в какое время веб-страницы 7sensetrade наиболее популярны и какой объем данных генерируется на веб-страницах 7sensetrade. Кроме того, файлы журналов позволяют 7sensetrade обнаруживать возможные ошибки, такие как ошибочные ссылки или программные ошибки. Таким образом, 7sensetrade может использовать файлы журналов для дальнейшего развития веб-страниц 7sensetrade. 7sensetrade никогда не связывает использование и показы страниц, которые хранятся в журнале сервера, с отдельными людьми. 7sensetrade оставляет за собой право использовать данные из файлов журнала, если есть подозрения, что пользователи используют веб-сайты и/или службы 7sensetrade незаконным или внедоговорным образом.',
  'components.routes.modals.Cookies.title':
    'Политика использования файлов cookie',
  'components.routes.modals.Docs.title': 'Справочный раздел',
  'components.routes.modals.DocsArticle.title': 'Справочный раздел',
  'components.routes.modals.ExchangeConnect.modalInactiveExchangeButton':
    'Подключить',
  'components.routes.modals.ExchangeConnect.subTitle': 'Биржа не подключена',
  'components.routes.modals.ExchangeConnect.title': 'Внимание!',
  'components.routes.modals.Exchanges.APIKey': 'API ключ',
  'components.routes.modals.Exchanges.save': 'Сохранить',
  'components.routes.modals.Exchanges.secretPart': 'Секретная часть API ключа',
  'components.routes.modals.Exchanges.title': 'Настройки биржи',
  'components.routes.modals.ForgotMe.button': 'Понятно',
  'components.routes.modals.ForgotMe.info':
    'После удаления аккаунта на вашу почту будет отправлено уведомление',
  'components.routes.modals.ForgotMe.subTitle':
    'Ваш аккаунт будет безвозвратно удалён через 30 дней',
  'components.routes.modals.ForgotMe.title': 'Аккаунт удалён',
  'components.routes.modals.ForgotPassword.title': 'Восстановление пароля',
  'components.routes.modals.ForgotPasswordChange.title': 'Изменение пароля',
  'components.routes.modals.Language.title': 'Выбор языка',
  'components.routes.modals.MarketplaceAdd.title': 'Создать бота',
  'components.routes.modals.MarketplaceBot.title': 'Характеристики бота',
  'components.routes.modals.MarketplaceBotEdit.title': 'Редактировать',
  'components.routes.modals.MarketplaceBotStatistics.title': 'Статистика бота',
  'components.routes.modals.Notifications.delete': 'Удалить все',
  'components.routes.modals.Notifications.read': 'Сделать прочитанными',
  'components.routes.modals.Notifications.title': 'Уведомления',
  'components.routes.modals.Privacy.h3_1':
    'Какую личную информацию мы собираем от людей?',
  'components.routes.modals.Privacy.h3_2': 'Когда мы собираем информацию?',
  'components.routes.modals.Privacy.h3_3': 'Как мы используем вашу информацию?',
  'components.routes.modals.Privacy.h3_4': 'Как мы защищаем вашу информацию?',
  'components.routes.modals.Privacy.h3_5': 'Ваш выбор:',
  'components.routes.modals.Privacy.point1_1':
    'При регистрации на нашем сайте вас могут попросить ввести ваше полное имя, адрес электронной почты, часовой пояс местоположения или другие данные, которые помогут вам в вашем пользовательском опыте.',
  'components.routes.modals.Privacy.point1_2':
    'Информация, которую мы собираем автоматически. Когда вы получаете доступ к нашим Услугам или используете их, мы автоматически собираем информацию о вас, включая информацию журнала.',
  'components.routes.modals.Privacy.point5_1_text':
    'Вы имеете право запросить, изменить или удалить свои личные данные, которые находятся в наших системах и на нашем сайте. Вы можете самостоятельно изменить или удалить свои личные данные с помощью соответствующих функций в любое время, войдя в свою учетную запись на нашем сайте или обратившись за помощью в нашу службу поддержки. Если вы хотите удалить свои личные данные, обратите внимание, что мы можем сохранить определенную информацию в соответствии с требованиями закона. Мы также можем сохранять кэшированные или архивные копии информации о вас в течение определенного периода времени.',
  'components.routes.modals.Privacy.point5_1_title':
    'Информация Об Учетной Записи.',
  'components.routes.modals.Privacy.point5_2_text':
    'Большинство веб-браузеров настроены на прием файлов cookie по умолчанию. При желании вы обычно можете настроить свой браузер на удаление или отклонение файлов cookie браузера, но обратите внимание что, если вы решите удалить или отклонить файлы cookie, это может повлиять на доступность и функциональность Услуг.',
  'components.routes.modals.Privacy.point5_2_title': 'Cookie.',
  'components.routes.modals.Privacy.point5_3_text':
    'Вы можете отказаться от получения рекламных электронных писем или текстовых сообщений, следуя инструкциям, приведенным в этих электронных или текстовых сообщениях. Если вы откажетесь, мы по-прежнему можем отправлять вам нерекламные сообщения, например, о вашей учетной записи или о наших текущих деловых отношениях.',
  'components.routes.modals.Privacy.point5_3_title': 'Рекламные коммуникации.',
  'components.routes.modals.Privacy.text1':
    '7sensetrade собирает, использует и хранит персональные данные пользователей в соответствии с действующим законодательством о защите данных. Персональные данные – любая информация, относящаяся к идентифицированному или идентифицируемому физическому лицу. Ниже 7sensetrade информирует всех пользователей веб-сайта о типе, объеме и цели сбора и использования персональных данных. Термины, определенные в Условиях предоставления услуг 7sensetrade, имеют здесь то же значение.',
  'components.routes.modals.Privacy.text2':
    'Политика распространяется на всех посетителей веб-сайта 7sensetrade.',
  'components.routes.modals.Privacy.text2_1':
    'Мы собираем информацию от вас, когда вы входите в систему, регистрируетесь или вводите информацию на нашем сайте.',
  'components.routes.modals.Privacy.text3_1':
    'Мы можем использовать информацию, которую мы получаем от вас, когда вы регистрируетесь, пополняете свой баланс, подписываетесь на нашу рассылку новостей, отвечаете на опросы или маркетинговые сообщения, просматриваете веб-сайт или используете некоторые другие функции сайта для быстрой обработки ваших транзакций. Если вы дадите нам свои контактные данные социальных сетей, то мы можем использовать их для добавления вас в наши официальные группы в социальных сетях, таких как Telegram, VK и других.',
  'components.routes.modals.Privacy.text4_1':
    'Наш веб-сайт регулярно сканируется на наличие брешей в системе безопасности и известных уязвимостей, чтобы сделать посещение нашего веб-сайта максимально безопасным. Мы используем обычное сканирование вредоносных программ. Ваша личная информация хранится в защищенных сетях и доступна только ограниченному числу лиц, которые имеют специальные права доступа к таким системам и обязаны сохранять конфиденциальность информации.',
  'components.routes.modals.Privacy.text4_2':
    'Кроме того, вся конфиденциальная/кредитная информация, которую вы предоставляете, шифруется с помощью технологии {text} Мы применяем различные меры безопасности, когда пользователь вводит, отправляет или получает доступ к своей информации, чтобы обеспечить безопасность вашей личной информации. Мы не передаем никакой информации о вас.',
  'components.routes.modals.Privacy.text4_2_ssl': 'Secure Socket Layer (SSL)',
  'components.routes.modals.Privacy.title': 'Политика конфиденциальности',
  'components.routes.modals.SignInEmail.title': 'Вход',
  'components.routes.modals.SignInEmailForm.signUpButton': 'Регистрация',
  'components.routes.modals.SignUpEmail.title': 'Регистрация',
  'components.routes.modals.Support.button': 'Отправить',
  'components.routes.modals.Support.email': 'E-mail',
  'components.routes.modals.Support.infoAlertSubtitle':
    'Укажите вашу почту для отправки сообщения в службу поддержки.',
  'components.routes.modals.Support.infoAlertTitle': 'Вы не авторизованы!',
  'components.routes.modals.Support.selectPlaceholder': 'Тип проблемы',
  'components.routes.modals.Support.textAreaPlaceholder': 'Ваше сообщение',
  'components.routes.modals.Support.title': 'Сообщить о проблеме',
  'components.routes.modals.SupportMessage.title': 'Заявка #{id}',
  'components.routes.modals.Terms.point1':
    '7sensetrade не несет ответственности в случаях технических неполадок на биржах, кражи средств с бирж, блокировки аккаунтов на биржах, некорректной работы API бирж, банкротств бирж, ошибочных действий пользователей, а также сильных и длительных изменений цен на биржах .',
  'components.routes.modals.Terms.point10':
    'Если баланс аккаунта 7sensetrade отрицательный, то у пользователя есть 3 дня на пополнение баланса. Через 3 дня аккаунт блокируется. Пользователь не может создавать новых ботов или запускать их.',
  'components.routes.modals.Terms.point11':
    'Если баланс аккаунта остается отрицательным более 7 дней, то работа ботов прерывается. Также приостанавливается отслеживание активных заказов.',
  'components.routes.modals.Terms.point12':
    'Баланс аккаунта 7sensetrade можно пополнить с помощью BTC, ETH, BNB, USDT. Депозиты в BTC, ETH, BNB, USDT будут автоматически конвертированы в BTC по курсу на момент депозита.',
  'components.routes.modals.Terms.point13_days': '3-х дней',
  'components.routes.modals.Terms.point13_text1':
    'Баланс аккаунта 7sensetrade не возвращается, за исключением случаев ошибочного пополнения.',
  'components.routes.modals.Terms.point13_text2':
    '{title} означает пополнение баланса аккаунта 7sensetrade вместо пополнения биржевого счета.',
  'components.routes.modals.Terms.point13_text3':
    'Возврат ошибочных пополнений производится при подаче запроса в службу поддержки, что необходимо сделать не позднее {days} с момента пополнения. Все запросы, сделанные позднее 3-х дней с момента ошибочного пополнения, не будут получены.',
  'components.routes.modals.Terms.point13_text4':
    'При возврате ошибочных депозитов мы взимаем 10% комиссию за ручную обработку в размере минимум 20 долларов США.',
  'components.routes.modals.Terms.point13_text5':
    'Возврат денежных средств производится в той же валюте, в которой производилось пополнение, пользователь должен учитывать это при указании адреса и счета, на который необходимо произвести возврат, при обращении в службу поддержки.',
  'components.routes.modals.Terms.point13_text6':
    'Возврат возможен только для пополнений баланса, оплаченных нам. Возврат средств не может быть осуществлен путем внутренних переводов от одного пользователя к другому.',
  'components.routes.modals.Terms.point13_title': 'Ошибочное пополнение ',
  'components.routes.modals.Terms.point14':
    'Манипуляции с балансом аффилированных счетов запрещены.',
  'components.routes.modals.Terms.point15':
    'Поддельные продажи услуг и конфигураций ботов на маркетплейсе запрещены.',
  'components.routes.modals.Terms.point16_text1':
    'Правила выставления конфигураций ботов на продажу на маркетплейс:',
  'components.routes.modals.Terms.point16_text2': 'Чтобы добавить на рынок:',
  'components.routes.modals.Terms.point16_text2_point1':
    'Описание бота должно содержать не менее 100 символов.',
  'components.routes.modals.Terms.point16_text2_point2':
    'Необходимо указать рабочий контакт для обратной связи (email, телеграм).',
  'components.routes.modals.Terms.point16_text2_point3':
    'Вы не можете использовать идентификаторы и имена других ботов из торговой площадки в имени бота.',
  'components.routes.modals.Terms.point16_text2_point4':
    'Цена размещаемой конфигурации не должна превышать 0,01 BTC.',
  'components.routes.modals.Terms.point16_text3':
    'Чтобы появиться на торговой площадке:',
  'components.routes.modals.Terms.point16_text3_point1':
    'Бот должен работать без изменения настроек не менее 7 дней. При изменении любых настроек бота, включая имя бота, кошелек бота и сумму в нем, бот не будет отображаться в маркетплейсе, пока не отработает с новыми настройками 7 дней.',
  'components.routes.modals.Terms.point16_text3_point2':
    'Депозит бота (сумма в кошельке бота) должен быть эквивалентен не менее 50 USD.',
  'components.routes.modals.Terms.point2':
    'Услуга предоставляется на условиях «как есть». 7sensetrade не гарантирует определенную доходность при использовании сервиса. Производительность бота определяется многими факторами, такими как волатильность на биржах, объем торгов и ликвидность на биржах, а также настройками бота. Все настройки сервиса доступны пользователю. Пользователь самостоятельно принимает решения о настройках и несет ответственность за убытки в случае неправильных или агрессивных торговых настроек.',
  'components.routes.modals.Terms.point3':
    'Мы не храним и не принимаем средства пользователей, а также не осуществляем никаких платежей (кроме выплат от продаж на маркетплейсе). Все средства хранятся на счетах пользователей на биржах. 7sensetrade не имеет доступа к выводу средств со счетов пользователей на биржах. Пользователь и соответствующие биржи несут ответственность за сохранность средств на счетах бирж. 7sensetrade работает со средствами пользователей для размещения ордеров на биржах через интерфейс API.',
  'components.routes.modals.Terms.point4':
    'Пользователь нашего сервиса может иметь только один аккаунт 7sensetrade. Запрещено регистрировать несколько аккаунтов. Если по каким-то причинам необходимо несколько учетных записей, то пользователю следует обратиться в службу поддержки. Запрещено торговать в разных учетных записях 7sensetrade, используя одну и ту же учетную запись на бирже криптовалют — это будет считаться наличием нескольких учетных записей для пользователя. Аккаунты с выявленными нарушениями будут заблокированы, а их средства заморожены.',
  'components.routes.modals.Terms.point5':
    'Пользователь нашего сервиса соглашается с тем, что в случае нарушения им любого условия или правила настоящего Пользовательского соглашения его учетная запись будет закрыта, а его средства заморожены.',
  'components.routes.modals.Terms.point6':
    '7sensetrade не несет ответственности за информацию о нашем сервисе, представленную на других сайтах, кроме {host}',
  'components.routes.modals.Terms.point7_text1': 'Оплата услуги.',
  'components.routes.modals.Terms.point7_text2':
    '7sensetrade берет только комиссию в размере 20% от прибыли, которую вы получаете при торговле с ботом. Для списания комиссии есть баланс аккаунта пользователя 7sensetrade. Баланс аккаунта 7sensetrade рассчитывается в BTC. Комиссия списывается с баланса аккаунта 7sensetrade каждый раз, когда бот завершает свой рабочий цикл и получает прибыль (на бирже исполняется тейк-профит ордер). Если доход получен не в BTC, то он конвертируется в BTC по курсу на момент получения дохода.',
  'components.routes.modals.Terms.point8':
    'Ограничение на количество ботов для одного аккаунта RevenueBot нет.',
  'components.routes.modals.Terms.point9':
    'Сервис 7sensetrade доступен в полном объеме при балансе счета 0 и более. Пользователь может создавать новых ботов и запускать их.',
  'components.routes.modals.Terms.text1':
    'Услуга предоставляется вам компанией 7sensetrade («7sensetrade», «бот», «мы», «нас» или «наш») через веб-сайт, расположенный по адресу {host} . Чтобы обеспечить «справедливую работу» и отличный опыт работы с 7sensetrade, мы создали эти условия, правила и положения.',
  'components.routes.modals.Terms.text2':
    '7sensetrade обеспечивает бесперебойную работу своих серверов в Интернете 24 часа в сутки. Пользователю не нужно устанавливать какое-либо программное обеспечение на свой компьютер для использования сервиса.',
  'components.routes.modals.Terms.text3':
    '7sensetrade оставляет за собой право вносить изменения, дополнения и корректировки в настоящие Условия использования без предварительного уведомления.',
  'components.routes.modals.Terms.title': 'Условия предоставления услуг',
  'components.routes.modals.VerifyPhoneNumber.title':
    'Подтвердить код телефона',

  'components.routes.pages.Admin.endpoint.all': 'Все эндпоинты',
  'components.routes.pages.Admin.endpoint.placeholder': 'Эндпоинт',
  'components.routes.pages.Admin.period': 'Период',
  'components.routes.pages.Admin.title': 'Администрирование',
  'components.routes.pages.Admin.userId.placeholder': 'ID пользователя',
  'components.routes.pages.AdminSupport.title': 'Техподдержка',
  'components.routes.pages.AdminSupportMessage.title': 'Заявка #{id}',
  'components.routes.pages.AdminWiki.title': 'Вики',
  'components.routes.pages.AdminWikiAdd.title': 'Добавить статью',
  'components.routes.pages.AdminWikiArticle.edit': 'Редактировать',
  'components.routes.pages.AdminWikiArticle.title': 'Статьи',
  'components.routes.pages.App.button': 'Создать бота',
  'components.routes.pages.App.title': 'Панель управления',
  'components.routes.pages.App.welcomeDescription':
    'Чтобы начать пользоваться сервисом, создайте своего первого бота',
  'components.routes.pages.App.welcomeTitle': 'Добро пожаловать!',
  'components.routes.pages.Cabinet.delete': 'Удалить аккаунт',
  'components.routes.pages.Cabinet.email': 'E-mail',
  'components.routes.pages.Cabinet.password': 'Пароль',
  'components.routes.pages.Cabinet.phone': 'Номер телефона',
  'components.routes.pages.Cabinet.signOut': 'Выйти',
  'components.routes.pages.Cabinet.title': 'Личный кабинет',
  'components.routes.pages.Cabinet.user': 'Здравствуйте, {user}!',
  'components.routes.pages.Cabinet.userId': 'ID пользователя',
  'components.routes.pages.Cabinet2FA.app.description':
    'Получить код проверки при входе в 7sensetrade также можно с помощью мобильного приложения для аутентификации (например, Google Authenticator или 1Password).',
  'components.routes.pages.Cabinet2FA.app.title':
    'Приложение для аутентификации',
  'components.routes.pages.Cabinet2FA.sms.description':
    'При входе в 7sensetrade мы будем отправлять на ваш мобильный телефон сообщение с кодом проверки.',
  'components.routes.pages.Cabinet2FA.sms.title': 'SMS-сообщение',
  'components.routes.pages.Cabinet2FA.text':
    'Защитите свою учетную запись от несанкционированного доступа, включив еще один метод аутентификации в дополнение к паролю. Вы можете выбрать SMS или приложение для аутентификации.',
  'components.routes.pages.Cabinet2FA.title': 'Двухфакторная аутентификация',
  'components.routes.pages.CabinetExchanges.disconnect': 'Отключить',
  'components.routes.pages.CabinetExchanges.modalDisconnectButton': 'Отключить',
  'components.routes.pages.CabinetExchanges.modalSubTitle':
    'Вы действительно хотите отключить биржу? Это действие остановит всех ваших ботов, работающих с этой биржей и отменит их ордера',
  'components.routes.pages.CabinetExchanges.modalTitle': 'Отключить биржу',
  'components.routes.pages.CabinetExchanges.title': 'Управление биржами',
  'components.routes.pages.CabinetNotifications.alertMessage':
    'Необходимо подключить биржу в настройках ',
  'components.routes.pages.CabinetNotifications.title': 'Уведомления',
  'components.routes.pages.ConfirmEmail.title':
    'Подтверждение адреса электронной почты',
  'components.routes.pages.Error404.body': 'Страница не найдена',
  'components.routes.pages.Error404.button': 'На главную',
  'components.routes.pages.Error404.title': 'Извините!',
  'components.routes.pages.Marketplace.add': 'Создать бота',
  'components.routes.pages.Marketplace.tabs.buy': 'Добавить',
  'components.routes.pages.Marketplace.tabs.sell': 'Продать',
  'components.routes.pages.Marketplace.title': 'Маркетплейс',
  'components.routes.pages.Root.title': '7SenseTrade',

  'error.endpoint.extensionNotExist': 'Расширение отсутствует',

  'error.endpoint.fileNotExist': 'Файл не отправлен',

  'error.endpoint.fileSizeExceeded': 'Превышен размер файла',

  'error.endpoint.needAddEmail': 'Необходимо привязать email к аккаунту',

  'error.endpoint.unsupportedFile': 'Формат файла не поддерживается',

  'src.constants.botStatuses.active': 'Работает',
  'src.constants.botStatuses.error': 'Ошибка',
  'src.constants.botStatuses.inactive': 'Отключён',

  'src.constants.exchanges.binanceDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.bitgetDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.bittrexDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.bybitDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.gateDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.huobiDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.kucoinDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',
  'src.constants.exchanges.poloniexDescription':
    'криптовалютная биржа для торговли биткоином, эфириумом и альткоинами',

  'src.constants.supportStatuses.completed': 'Обработано',
  'src.constants.supportStatuses.pending': 'Ожидает',

  'src.constants.supportThemes.account': 'Мой аккаунт',
  'src.constants.supportThemes.api': 'API-ключи',
  'src.constants.supportThemes.bot': 'Бот: настройка, создание и управление',
  'src.constants.supportThemes.finance': 'Комиссии, депозиты и кошельки',
  'src.constants.supportThemes.marketplace': 'Маркетплейс',
};
