import { compile } from 'path-to-regexp';

export enum PAGES {
  ROOT = '/',

  APP = '/app',
  CABINET = '/app/cabinet',
  CABINET_2FA = '/app/cabinet/2fa',
  CABINET_EXCHANGES = '/app/cabinet/exchanges',
  CABINET_NOTIFICATIONS = '/app/cabinet/notifications',

  MARKETPLACE = '/app/marketplace',
  MARKETPLACE_SELL = '/app/marketplace/sell',

  ADMIN = '/app/admin',
  ADMIN_WIKI = '/app/admin/wiki',
  ADMIN_SUPPORT = '/app/admin/support',
  ADMIN_SUPPORT_MESSAGE = '/app/admin/support/:id',

  CONFIRM_EMAIL = '/confirm/email/:code',

  ERROR_404 = '*',
}

export enum MODALS {
  ABOUT_US = '/about-us',
  CONTACTS = '/contacts',
  COOKIES = '/cookies',
  PRIVACY = '/privacy',
  TERMS = '/terms',
  DOCS = '/docs',
  DOCS_ARTICLE = '/docs/:id',
  NOTIFICATIONS = '/notifications',

  ADMIN_WIKI_ADD = '/app/admin/wiki/add',
  ADMIN_WIKI_ARTICLE = '/app/admin/wiki/:id',
  ADMIN_WIKI_ARTICLE_EDIT = '/app/admin/wiki/:id/edit',

  SUPPORT = '/support',
  SUPPORT_MESSAGE = '/support/:id',

  LANGUAGE = '/language',

  EXCHANGE = '/exchange/:name',
  EXCHANGE_EDIT = '/exchange/:name/edit',
  EXCHANGE_CONNECT = '/exchange/connect',

  CONTACTS_EMAIL = '/contacts/email',
  CONTACTS_PASSWORD = '/contacts/password',
  CONTACTS_PHONE = '/contacts/phone',
  CONTACTS_MESSENGERS = '/contacts/:contact',

  CABINET_DELETE = '/delete',
  CABINET_SIGN_OUT = '/sign-out',

  SIGN_IN_EMAIL = '/sign-in/email',
  SIGN_UP_EMAIL = '/sign-up/email',
  SIGN_UP_PHONE = '/sign-up/phone/:phone',

  FORGOT_PASSWORD = '/forgot/password',
  FORGOT_PASSWORD_CHANGE = '/forgot/password/change/:code',
  FORGOT_ME = '/forgot/me',

  MARKETPLACE_BOT_EDIT = '/app/marketplace/:id/edit',
  MARKETPLACE_BOT_STATISTIC = '/app/marketplace/:id/statistic',
  MARKETPLACE_BOT = '/app/marketplace/:id',
  MARKETPLACE_ADD = '/app/marketplace/add',

  ADD_WALLET = '/add-wallet',
}

export enum ENDPOINTS {
  ROOT = '/',

  CONFIRM_EMAIL = '/confirm/email',
  CONFIRM_PHONE = '/confirm/phone',

  EXCHANGE_INFO_WITH_EXCHANGE = '/exchange-info/:name',

  LOG = '/log',

  PASSWORD_CHANGE = '/password/change',
  PASSWORD_FORGOT = '/password/forgot',
  PASSWORD_FORGOT_CHANGE = '/password/forgot/change',

  SIGN_IN_EMAIL = '/sign-in/email',
  SIGN_UP_EMAIL = '/sign-up/email',
  SIGN_UP_PHONE = '/sign-up/phone',
  SIGN_UP_PHONE_CODE = '/sign-up/phone/code',
  SIGN_UP_VK = '/sign-up/vk',
  SIGN_UP_GOOGLE = '/sign-up/google',
  SIGN_OUT = '/sign-out',

  BOT = '/bot',
  BOT_BUY_WITH_ID = '/bot/buy/:id',
  BOT_MY = '/bot/my',
  BOT_FINANCE = '/bot/finance',
  BOT_WITH_ID = '/bot/:id',
  BOT_STATISTIC_STATUS = '/bot/statistic/status',
  BOT_WITH_ID_ORDERS = '/bot/:id/orders',

  BALANCE_TOP_UP = '/balance/top-up',

  PROFILE = '/profile',
  PROFILE_WITH_ID = '/profile/:id',
  PROFILE_EMAIL = '/profile/email',
  PROFILE_EXCHANGE = '/profile/exchange',
  PROFILE_NOTIFICATIONS_WITH_CONTACT = '/profile/notifications/:contact',

  PROFILE_MESSENGER_VK = '/profile/messenger/vk',
  PROFILE_MESSENGER_VK_WEBHOOK = '/profile/messenger/vk/webhook',
  PROFILE_MESSENGER_TELEGRAM = '/profile/messenger/telegram',
  PROFILE_MESSENGER_WHATSAPP = '/profile/messenger/whatsapp',
  PROFILE_MESSENGER_WHATSAPP_WEBHOOK = '/profile/messenger/whatsapp/webhook',

  PROFILE_EXCHANGE_WITH_NAME = '/profile/exchange/:name',
  PROFILE_PHONE = '/profile/phone',

  SUPPORT = '/support',
  SUPPORT_WITH_ID = '/support/:id',
  SUPPORT_REPLY = '/support/reply',
  SUPPORT_FILE_IMAGE = '/support/file/image',

  WIKI = '/wiki',
  WIKI_WITH_ID = '/wiki/:id',
}

export type Path = {
  pathname: string;
  search?: string;
};

type ParsedPathname = string[];
type ParsedSearchParams = Record<string, string>;

export type Scheme<
  Endpoints extends PAGES | ENDPOINTS | MODALS = PAGES | ENDPOINTS | MODALS
> = {
  scheme: Endpoints;
  params?: Record<string, string>;
  getParams?: ParsedSearchParams;
};

export type FeScheme = Scheme<PAGES> | [Scheme<PAGES>, Scheme<MODALS>];

const DELIMITER = '/~';

const allPages = Object.values(PAGES);
const allModals = Object.values(MODALS);

const isPage = (path: string) => allPages.includes(path as never);
const isModal = (path: string) => allModals.includes(path as never);

const parsePathname = (path = ''): ParsedPathname => {
  const parts = path.split(DELIMITER).filter((item) => !!item);
  return path.startsWith(DELIMITER) ? ['/', ...parts] : parts;
};

const compilePathname = (
  prev: ParsedPathname,
  scheme: Scheme<PAGES | MODALS>['scheme'],
  params: Scheme<PAGES | MODALS>['params']
): ParsedPathname => {
  const isCompilable = scheme.includes(':') || params;
  const compiled = isCompilable ? compile(scheme)(params) : scheme;
  const filtered = prev.filter((item) => item !== compiled);

  if (isPage(scheme) && filtered.length) {
    throw new Error('Use option `mode=replace`');
  }

  if (isModal(scheme) && !filtered.length) {
    throw new Error('Must specify page or use option `mode=detect`');
  }

  return [...filtered, compiled];
};

const parseQueryString = (path = ''): ParsedSearchParams =>
  path
    .slice(1)
    .split('&')
    .reduce((acc, item) => {
      const [key, value] = item.split('=');
      return key ? { ...acc, [key]: value } : acc;
    }, {});

const serializeQueryString = (params: ParsedSearchParams): string | undefined =>
  Object.keys(params).length
    ? `?${Object.entries(params)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    : undefined;

export const makeSimplePath = <T extends PAGES | ENDPOINTS | MODALS>({
  scheme,
  params,
  getParams,
}: Scheme<T>) => {
  const isCompilable = scheme.includes(':') || params;
  const compiled = isCompilable ? compile(scheme)(params) : scheme;

  return compiled + (serializeQueryString(getParams ?? {}) ?? '');
};

export const makeHierarchicalPath = (
  from: Path | undefined,
  to: Scheme<PAGES | MODALS>[],
  mode: 'replace' | 'detect'
): Path | null => {
  if (!to || !to.length) {
    return null;
  }

  try {
    const precompiled = to.reduce<{
      pathname: ParsedPathname;
      search: ParsedSearchParams;
    }>(
      (acc, { scheme, params, getParams }) => {
        acc.pathname = compilePathname(acc.pathname, scheme, params);

        if (getParams) {
          acc.search = { ...acc.search, ...getParams };
        }

        return acc;
      },
      mode === 'replace' || (mode === 'detect' && isPage(to[0].scheme))
        ? { pathname: [], search: {} }
        : {
            pathname: parsePathname(from?.pathname),
            search: parseQueryString(from?.search),
          }
    );

    return {
      pathname: precompiled.pathname.join(DELIMITER).replaceAll('//', '/'),
      search: serializeQueryString(precompiled.search),
    };
  } catch (error) {
    console.error('makeURL', error);
    return null;
  }
};
