/**
 * https://ant.design/components/drawer/
 */
import AntDrawer from 'antd/lib/drawer';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { CloseIcon } from 'src/components/atoms/CloseIcon';
import { useIsMobile } from 'src/hooks/useIsMobile';
import { DictionaryKey } from 'src/i18n';
import { msg } from 'src/i18n/Msg';

import styles from 'src/components/atoms/Drawer/styles.module.css';

export type SizeProp = keyof typeof sizeToWidth;

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: DictionaryKey;
  params?: string;
  size: SizeProp;
  isMenu?: boolean;
};

const sizeToWidth = {
  default: 464,
  medium: 904,
  large: 1344,
} as const;

const getDrawerWidth = (isMobile: boolean, size: SizeProp) =>
  isMobile ? '100%' : sizeToWidth[size];

export const Drawer: React.FC<Props> = ({
  children,
  isOpen,
  onClose,
  title,
  params = '{}',
  size,
  isMenu,
}) => {
  const intl = useIntl();
  const isMobile = useIsMobile();

  const values = JSON.parse(params);
  const { name } = values;

  return (
    <AntDrawer
      className={classNames(styles.drawer, isMenu && styles.drawer_menu)}
      onClose={onClose}
      placement="right"
      title={
        name
          ? name.toUpperCase()
          : title
          ? msg(intl, { id: title, values })
          : undefined
      }
      open={isOpen}
      keyboard={false}
      maskClosable={false}
      height="auto"
      width={getDrawerWidth(isMobile, size)}
      closeIcon={<CloseIcon />}
    >
      {children}
    </AntDrawer>
  );
};
