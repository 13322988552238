type PublicEnv = 'local' | 'staging' | 'production';

export const PUBLIC_ENV = process.env.REACT_APP_PUBLIC_ENV as PublicEnv;
export const APP_VERSION = process.env.REACT_APP_VERSION || '';

export const INTEGRATIONS = {
  ROLLBAR: {
    ENABLED: PUBLIC_ENV !== 'local',
    TOKEN: process.env.REACT_APP_INTEGRATIONS_ROLLBAR_TOKEN || '',
  },
  SUPPORT: {
    ENABLED: true,
  },
  VK: {
    ENABLED: true,
    APP_ID: 51593770,
    WEBSITE_URL: 'https://vk.com/sevensensetrade',
  },
  GOOGLE: {
    ENABLED: true,
    CLIENT_ID:
      '480800540397-nci3borjt43e2qpn8ea0ce4irsf2bl8e.apps.googleusercontent.com',
  },
  TELEGRAM: {
    ENABLED: true,
    WEBSITE_URL: 'https://t.me/Sevensensetrade',
  },
  SMS: {
    ENABLED: false,
  },
} as const;

const configs: Record<PublicEnv, { API_HOST: string; CLIENT_HOST: string }> = {
  local: {
    API_HOST: 'localhost:8000',
    CLIENT_HOST: 'localhost:3000',
  },
  staging: {
    API_HOST: 'api.dev.7sensetrade.com',
    CLIENT_HOST: 'dev.7sensetrade.com',
  },
  production: {
    API_HOST: 'api.7sensetrade.com',
    CLIENT_HOST: '7sensetrade.com',
  },
};

export const { API_HOST, CLIENT_HOST } = configs[PUBLIC_ENV];
