import React from 'react';

export const MainPanelIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m14.97 1.203.057.005a12.833 12.833 0 0 1 11.767 11.766l.005.057c.02.213.063.667-.124 1.092-.154.35-.433.652-.77.833-.256.14-.503.18-.684.197a5.27 5.27 0 0 1-.458.014h-9.855c-.139 0-.305 0-.451-.012a1.76 1.76 0 0 1-.666-.18 1.75 1.75 0 0 1-.765-.764 1.762 1.762 0 0 1-.18-.666 5.751 5.751 0 0 1-.01-.451V3.239c-.001-.137-.001-.307.013-.458.016-.182.058-.428.196-.685.182-.336.484-.615.834-.77.425-.187.879-.144 1.091-.123Zm.198 2.362v9.268h9.269a10.501 10.501 0 0 0-9.269-9.268Zm-4.764-.73a1.167 1.167 0 0 1-.602 1.536 10.5 10.5 0 1 0 13.87 13.713 1.167 1.167 0 1 1 2.15.909A12.834 12.834 0 1 1 8.867 2.233a1.167 1.167 0 0 1 1.536.602Z"
      clipRule="evenodd"
    />
  </svg>
);
