// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/ru.po' and run '.po' extractor
export const events = {
  'error.addWallet.required': 'Поле обязательно для заполнения',

  'error.apiKey.min': 'API ключ слишком короткий',
  'error.apiKey.required': 'Необходимо указать API ключ',

  'error.code.min': 'Код не может быть короче 4 символов',
  'error.code.required': 'Требуется ваш код',

  'error.confirmationCode.expired': 'Код подтверждения устарел',
  'error.confirmationCode.notValid': 'Неверный код подтверждения',

  'error.contact.notLinked': 'Контакт не привязан',

  'error.createArticle.required': 'Поле обязательно для заполнения',

  'error.createBot.required': 'Поле обязательно для заполнения',

  'error.email.alreadyExists': 'Пользователь с таким email уже существует',
  'error.email.format': 'Вы указали невалидный email',
  'error.email.notLinked': 'Email не привязан к аккаунту',
  'error.email.notValid': 'Неверный email',
  'error.email.required': 'Необходимо указать ваш email',
  'error.email.send': 'Ошибка при отправке email сообщения',

  'error.emailOrPassword.notValid': 'Неверный email или пароль',

  'error.endpoint.botIsBought': 'Бот уже приобретён',
  'error.endpoint.botIsNotExist': 'Бот не существует',
  'error.endpoint.botStatusError': 'Невозможно установить статус "error"',
  'error.endpoint.equalCurrency': 'Невозвожно установить одинаковые валюты',
  'error.endpoint.impossibleChangeStatus':
    'Изменить статус активного бота невозможно',
  'error.endpoint.impossibleUpdateErrorBot':
    'Невозможно изменить бота с ошибкой. Выключите бота и повторите еще раз',
  'error.endpoint.invalidDate': 'Неверная дата',
  'error.endpoint.invalidId': 'Неверный id',
  'error.endpoint.invalidPhone': 'Неверный телефонный номер',
  'error.endpoint.messengerLinked': 'Этот мессенджер уже привязан к аккаунту',
  'error.endpoint.notValid': 'Эндпоинт настроен некорректно',
  'error.endpoint.pairNotExist': 'Торговая пара не существует',
  'error.endpoint.stopLossOverlap': 'Сумма стоп-лосс и перекрытия больше 99',
  'error.endpoint.wikiArticleIsNotExist': 'Такая статья не существует',

  'error.exhange.linked': 'Биржа уже привязана',
  'error.exhange.notLinked': 'Биржа не привязана',

  'error.file.download': 'Error downloading the file',

  'error.login.accountHaventRegisteredEmail':
    'У выбранного аккаунта отсутствует привязанная почта',
  'error.login.accountHaventRegisteredInfo':
    'На вашем аккаунте нет иформации для регистрации',
  'error.login.accountInDeletionProcess':
    'Аккаунт с этой почтой в процессе удаления.',
  'error.login.accountIsService': 'Выбранный аккаунт является сервисным',
  'error.login.accountPartialFilled': 'Выбранный аккаунт заполнен не полностью',
  'error.login.alreadyLoggedIn': 'Вы уже авторизованы',
  'error.login.need': 'Сначала необходимо авторизоваться',

  'error.message.notFound': 'Сообщение поддержки не найдено',
  'error.message.required': 'Необходимо заполнить это поле.',

  'error.password.min': 'Пароль не может быть короче 8 символов',
  'error.password.notValid': 'Неверный пароль',
  'error.password.required': 'Необходимо указать ваш пароль',

  'error.passwordNew.notMatch': 'Пароли должны совпадать',
  'error.passwordNew.required': 'Необходимо указать ваш пароль',

  'error.permissionDenied': 'Доступ запрещен',

  'error.phone.notLinked': 'Телефон не привязан',

  'error.price.lessOrEqual0': 'Значение не может быть меньше либо равно 0',

  'error.regionNotSupported': 'Регион не поддерживается',

  'error.secretKey.required': 'Необходимо указать секретная часть API ключа',

  'error.terms.needToAccept': 'Вам необходимо принять условия обслуживания',

  'error.text.required': 'Необходимо заполнить это поле.',

  'error.theme.required': 'Необходимо заполнить это поле.',

  'error.topUpAmount.min': 'Количество меньше минимального значения',

  'error.topUpBalance.amountShouldMoreZero':
    "Поле 'amount' должно быть больше нуля",

  'error.user.notFound': 'Пользователь не найден',

  'success.contacts.sent': 'Ваш запрос успешно отправлен.',

  'success.email.sent':
    'На указанную почту отправлено письмо для подтверждения',

  'success.exchange.connected': 'Настройки биржи сохранены',
  'success.exchange.disconnected': 'Настройки биржи удалены',
  'success.exchange.updated': 'Настройки биржи обновлены',

  'success.password.changed': 'Ваш пароль был успешно изменен',

  'success.phone.sent':
    'На указанный номер телефона отправлен код для подтверждения',

  'success.support.sent':
    'Ваш запрос успешно отправлен. Совсем скоро мы свяжемся с вами!',

  'success.supportReply.sent': 'Ваш ответ был успешно отправлен',
};
