export const CONTACTS = {
  EMAILS: {
    NOREPLY: 'post@7sensetrade.com',
    SUPPORT: 'support@7sensetrade.com',
  },
} as const;

export const ORDER_TYPES = [
  'LIMIT',
  'LIMIT_MAKER',
  'MARKET',
  'STOP',
  'STOP_MARKET',
  'STOP_LOSS_LIMIT',
  'TAKE_PROFIT_LIMIT',
  'TAKE_PROFIT_MARKET',
  'TRAILING_STOP_MARKET',
] as const;
export const ORDER_STATUS = ['active', 'canceled', 'done', 'error'] as const;
export const ORDER_SIDE = ['BUY', 'SELL'] as const;
export const ORDER_CANCEL_REASON = [
  'EXCHANGE',
  'TRADING_ALGORITHM',
  'STOP_BOT',
  'EXPIRED',
  'BALANCE',
] as const;

export const BOT_STATUS = ['active', 'inactive', 'error'] as const;
export const EXCHANGES = [
  'binance',
  'bybit',
  'huobi',
  'gate',
  'bitget',
  'kucoin',
  'poloniex',
  'bittrex',
] as const;
export const TRADING_ALGORITHMS = ['LONG', 'SHORT'] as const;
export const STATUS_CYCLES = [
  'not_started',
  'in_progress',
  'error',
  'done',
] as const;

export const ACCOUNT_STATUS = ['active', 'process_delete', 'deleted'] as const;

export const PAYMENT_SYSTEM_CURRENCIES = ['BTC', 'USDT'] as const;

export const LOG_METHODS = ['POST', 'PUT', 'DELETE'] as const;
export type LOG_METHOD = (typeof LOG_METHODS)[number];

export const MAX_COUNT_ATTACH_SUPPORT_MESSAGE = 5 as const;

export const MINIMAL_TOP_UP_AMOUNT: Record<
  (typeof PAYMENT_SYSTEM_CURRENCIES)[number],
  number
> = {
  BTC: 0.00000001,
  USDT: 0.000001,
};
