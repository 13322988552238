import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

export const getPhoneNumberInstance = PhoneNumberUtil.getInstance;

/**
 * @example getCleanNumber('(999)-99-99-999', 'RU') => "9999999999";
 *
 * Attention!
 * Incorrect number or region will be trigger throw Error
 * Before applying, use the function checkIsPhoneValid
 */
export const getCleanNumber = (phoneNumber: string, region: string): string =>
  String(
    getPhoneNumberInstance()
      .parseAndKeepRawInput(phoneNumber, region)
      .getNationalNumber()
  );

/**
 * @example parseNumberFromRawString('+79183456789') => {region: RU, phone: 9183456789}
 */
export const parseNumberFromRawString = (
  phoneNumber: string /* with country code */
): { phone: string; region: string } => {
  const phoneUtil = getPhoneNumberInstance();
  const gPhone = phoneUtil.parseAndKeepRawInput(phoneNumber);

  return {
    phone: String(gPhone.getNationalNumber()) as string,
    region: phoneUtil.getRegionCodeForNumber(gPhone) as string,
  };
};

export const makePhoneNumberFromNumberAndRegion = (
  phone: string,
  region: string
) => {
  const phoneUtil = getPhoneNumberInstance();
  const gPhone = phoneUtil.parseAndKeepRawInput(phone, region);

  return phoneUtil.format(gPhone, PhoneNumberFormat.E164);
};

/**
 * @description +79189999999 -> 79189999999
 */
export const getNumberWithoutPlus = (phoneNumber: string, region?: string) => {
  const phoneUtil = getPhoneNumberInstance();

  const gNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, region);
  const countryCode = gNumber.getCountryCode();
  const nationalNumber = gNumber.getNationalNumber();

  return `${countryCode}${nationalNumber}`;
};
