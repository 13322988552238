import { QueryObserver, useQueryClient } from '@tanstack/react-query';
import React, { ReactNode, useEffect, useState } from 'react';
import { IntlProvider as Provider } from 'react-intl';

import { Locales, defaultLocale } from 'shared';

import { MODELS, store } from 'src/fetcher/store';
import { locales } from 'src/i18n';
import { changeLocale } from 'src/utils/dates';

type Props = {
  children: ReactNode;
};

const navigatorLocale: Locales = navigator.language
  .toLowerCase()
  .split('-')
  .includes('ru')
  ? 'ru'
  : defaultLocale;

export const IntlProvider: React.FC<Props> = ({ children }) => {
  const queryClient = useQueryClient();
  const storedLocale = store.get<Locales>(queryClient, MODELS.LOCALE);

  const [locale, setLocale] = useState<Locales>(
    storedLocale ?? navigatorLocale
  );

  useEffect(() => {
    const observer = new QueryObserver(queryClient, {
      queryKey: [MODELS.LOCALE],
    });

    return observer.subscribe(({ data }) => {
      if (data) {
        setLocale(data as Locales);
        changeLocale(data as Locales);
      }
    });
  }, [queryClient]);

  return (
    <Provider
      defaultLocale={defaultLocale}
      key={locale}
      locale={locale}
      messages={locales[locale].dictionary}
      textComponent={React.Fragment}
    >
      {children}
    </Provider>
  );
};
