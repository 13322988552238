import { QueryClient } from '@tanstack/react-query';

import { Locales } from 'shared';

import {
  ModelsBot,
  ModelsOrder,
  ModelsProfile,
  ModelsSupportMessage,
  ModelsWikiArticle,
  RootGetReplyStatus200,
} from 'src/_generated';

export enum MODELS {
  LOG = 'log',
  BOT = 'bot',
  BOT_BUY = 'bot_buy',
  BOT_MY = 'bot_my',
  BOT_STATISTIC_STATUS = 'bot_statistic_status',
  BOT_FINANCE = 'bot_finance',
  LOCALE = 'locale',
  PROFILE = 'profile',
  ROOT = 'root',
  BOT_ORDERS = 'orders',
  SUPPORT = 'support',
  CHAT = 'chat',
  WIKI = 'wiki',
  ARTICLE = 'article',
  NAVBAR_EXPAND = 'navbar_expand',
}

interface BotData {
  pages: { bots?: ModelsBot[]; nextCursor?: string }[];
  params?: string[];
}

interface ArticleData {
  pages: { articles?: ModelsWikiArticle[]; nextCursor?: string }[];
  params?: string[];
}

interface OrderData {
  pages: { orders?: ModelsOrder[]; nextCursor?: string }[];
  params?: string[];
}

export const store = {
  get: <T>(client: QueryClient, model: MODELS) =>
    client.getQueryData<T>([model]),
  locale: (client: QueryClient, data: Locales) => {
    client.setQueryData([MODELS.LOCALE], () => data);
  },
  navbarExpand: (client: QueryClient, data: boolean) => {
    client.setQueryData([MODELS.NAVBAR_EXPAND], () => data);
  },
  profile: (client: QueryClient, data: ModelsProfile) => {
    client.setQueryData([MODELS.PROFILE], () => data);
    client.setQueryData([MODELS.LOCALE], () => data.regional.locale);
    client.setQueryData<Partial<RootGetReplyStatus200>>(
      [MODELS.ROOT],
      (prev) => ({ ...prev, isAuthenticated: true })
    );
  },
  clear: (client: QueryClient) => client.clear(),
  bot: (client: QueryClient, data: ModelsBot) => {
    client.setQueryData([MODELS.BOT_MY], (oldData: BotData | undefined) => {
      const prevData = oldData?.pages[0];
      const botIndex = prevData?.bots?.findIndex(
        (item) => item._id === data._id
      );

      let newData = prevData?.bots;

      if (newData && newData.length) {
        if (botIndex !== undefined && botIndex !== -1) {
          newData[botIndex] = data;
        } else {
          newData = [data, ...newData];
        }

        return {
          ...oldData,
          pages: [{ ...prevData, bots: newData }],
        };
      }
    });
    client.setQueryData([MODELS.BOT_BUY], (oldData: BotData | undefined) => {
      const prevData = oldData?.pages[0];
      const botIndex = prevData?.bots?.findIndex(
        (item) => item._id === data._id
      );
      const newData = prevData?.bots || [];
      if (
        botIndex !== undefined &&
        botIndex !== -1 &&
        data.tradingAlgorithm === 'LONG'
      ) {
        newData[botIndex] = data;
      }
      return {
        ...oldData,
        pages: [{ ...prevData, bots: newData }],
      };
    });
    client.setQueryData([MODELS.BOT, { id: data._id }], () => data);
  },

  article: (client: QueryClient, data: ModelsWikiArticle) => {
    client.setQueryData([MODELS.WIKI], (oldData: ArticleData | undefined) => {
      const prevData = oldData?.pages[0];
      const botIndex = prevData?.articles?.findIndex(
        (item) => item._id === data._id
      );

      let newData = prevData?.articles;

      if (newData && newData.length) {
        if (botIndex !== undefined && botIndex !== -1) {
          newData[botIndex] = data;
        } else {
          newData = [data, ...newData];
        }

        return {
          ...oldData,
          pages: [{ ...prevData, articles: newData }],
        };
      }
    });

    client.setQueryData([MODELS.ARTICLE, { id: data._id }], () => data);
  },

  orders: (client: QueryClient, data: ModelsOrder) => {
    client.setQueryData(
      [MODELS.BOT_ORDERS],
      (oldData: OrderData | undefined) => {
        const prevData = oldData?.pages[0];
        const orderIndex = prevData?.orders?.findIndex(
          (item) => item.botId === data.botId
        );
        let newData = prevData?.orders || [];
        if (orderIndex !== undefined && orderIndex !== -1) {
          newData[orderIndex] = data;
        } else {
          newData = newData.concat(data);
        }
        return {
          ...oldData,
          pages: [{ ...prevData, orders: newData }],
        };
      }
    );
  },
  supportMessage: (client: QueryClient, data: ModelsSupportMessage[]) => {
    client.setQueryData([MODELS.CHAT, { id: data?.[0]._id }], () => data);
  },
} as const;
