import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const expires = 1000 * 60 * 60 * 24; // 24 hours

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: expires,
      staleTime: expires,
    },
  },
});

export const QueryProvider: React.FC<Props> = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    {children}

    {!process.env.STORYBOOK && <ReactQueryDevtools />}
  </QueryClientProvider>
);
