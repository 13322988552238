import React from 'react';

export const MarketplaceIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.167 8.183v2.318a1.167 1.167 0 0 0 2.334 0V8.168h7v2.333a1.167 1.167 0 1 0 2.333 0V8.183c.154.006.294.013.424.024.469.036.714.103.89.186.418.199.768.517 1.004.915.1.168.189.405.27.868.082.478.14 1.097.226 2.011l.7 7.467c.102 1.082.17 1.82.175 2.393.005.56-.054.845-.139 1.042-.206.48-.567.877-1.026 1.128-.188.102-.467.188-1.025.235-.57.048-1.312.05-2.398.05H9.066c-1.086 0-1.828-.002-2.398-.05-.558-.047-.837-.133-1.025-.236a2.334 2.334 0 0 1-1.027-1.127c-.085-.197-.144-.482-.139-1.042.005-.573.074-1.311.175-2.393l.7-7.466c.086-.915.145-1.534.227-2.012.08-.463.17-.7.27-.868a2.333 2.333 0 0 1 1.004-.915c.176-.083.42-.15.89-.186.13-.01.27-.018.423-.024ZM19.72 5.846c.259.006.499.017.72.034.61.048 1.171.15 1.708.405a4.667 4.667 0 0 1 2.01 1.83c.303.51.458 1.06.562 1.663.1.578.167 1.288.247 2.146l.71 7.563c.095 1.019.173 1.856.18 2.539.006.706-.061 1.361-.329 1.984a4.667 4.667 0 0 1-2.053 2.255c-.595.325-1.241.453-1.945.512-.68.058-1.522.058-2.545.058H9.016c-1.023 0-1.864 0-2.544-.058-.704-.06-1.35-.187-1.945-.512a4.667 4.667 0 0 1-2.054-2.255c-.267-.623-.334-1.278-.328-1.984.006-.683.085-1.52.18-2.54l.71-7.562c.08-.857.146-1.568.246-2.146.105-.603.26-1.152.563-1.663a4.667 4.667 0 0 1 2.01-1.83c.536-.254 1.097-.357 1.707-.405.222-.017.462-.028.72-.034a5.835 5.835 0 0 1 11.439 0Zm-2.418-.011a3.501 3.501 0 0 0-6.602 0h6.602Z"
      clipRule="evenodd"
    />
  </svg>
);
