/**
 * https://ant.design/components/spin/
 */
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import Spin from 'antd/lib/spin';
import React from 'react';

import styles from 'src/components/atoms/Preloader/styles.module.css';

type Props = {
  fullScreen?: boolean;
};

export const Preloader: React.FC<Props> = ({ fullScreen }) => {
  const spin = <Spin indicator={<LoadingOutlined spin />} />;

  return fullScreen ? <div className={styles.fullScreen}>{spin}</div> : spin;
};
