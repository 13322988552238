import React from 'react';

export const AccountIcon: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14 4.665a4.083 4.083 0 1 0 0 8.167 4.083 4.083 0 0 0 0-8.167ZM7.583 8.75a6.417 6.417 0 1 1 12.834 0 6.417 6.417 0 0 1-12.834 0Zm3.298 8.166H17.119c1.432 0 2.41 0 3.241.252a5.833 5.833 0 0 1 3.889 3.888c.252.832.252 1.81.251 3.241v.203a1.167 1.167 0 0 1-2.333 0c0-1.713-.013-2.311-.15-2.766a3.5 3.5 0 0 0-2.334-2.334c-.455-.138-1.053-.15-2.766-.15h-5.834c-1.713 0-2.31.012-2.766.15a3.5 3.5 0 0 0-2.333 2.334c-.138.455-.15 1.053-.15 2.766a1.167 1.167 0 0 1-2.334 0v-.203c0-1.432 0-2.41.251-3.24a5.833 5.833 0 0 1 3.89-3.89c.83-.252 1.808-.251 3.24-.25Z"
      clipRule="evenodd"
    />
  </svg>
);
