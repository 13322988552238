import { PhoneNumberUtil } from 'google-libphonenumber';

export const checkIsPhoneValid = (
  phoneNumber: string,
  region: string
): boolean => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  try {
    return phoneUtil.isValidNumberForRegion(
      phoneUtil.parse(phoneNumber, region),
      region
    );
  } catch (e) {
    // If incorrect phoneNumber or region throwed error
    return false;
  }
};
