export enum NOTIFICATIONS {
  /* Change email */
  CHANGING_EMAIL = 'CHANGING_EMAIL',
  CHANGED_EMAIL = 'CHANGED_EMAIL',

  /* Change phone */
  CHANGED_PHONE = 'CHANGED_PHONE',
  CHANGING_PHONE_SEND_OTP = 'CHANGING_PHONE_SEND_OTP',

  /*Password */
  CHANGED_PASSWORD = 'CHANGED_PASSWORD',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_CHANGE = 'FORGOT_PASSWORD_CHANGE',

  /* Sign up */
  SIGN_UP_EMAIL = 'SIGN_UP_EMAIL',

  /* OTP */
  VERIFY_OTP = 'VERIFY_OTP',

  /* Support */
  SUPPORT_REPLY = 'SUPPORT_REPLY',
}

export const NOTIFICATION_TYPES = ['security', 'system'] as const;
export type NOTIFICATION_TYPE = (typeof NOTIFICATION_TYPES)[number];

export const NOTIFICATION_TO_TYPE: Record<NOTIFICATIONS, NOTIFICATION_TYPE> = {
  CHANGED_EMAIL: 'security',
  CHANGED_PASSWORD: 'security',
  CHANGED_PHONE: 'security',

  CHANGING_EMAIL: 'system',
  CHANGING_PHONE_SEND_OTP: 'system',
  FORGOT_PASSWORD_CHANGE: 'system',
  FORGOT_PASSWORD: 'system',
  SIGN_UP_EMAIL: 'system',
  VERIFY_OTP: 'system',
  SUPPORT_REPLY: 'system',
};
