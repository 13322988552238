// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/en.po' and run '.po' extractor
export const base = {
  'components.atoms.Copyright.text':
    'Copyright ©{currentYear} Produced by 7SenseTrade',

  'components.components.molecules.QuickSingnIn.modalInactiveExchangeButton':
    'Follow the link',
  'components.components.molecules.QuickSingnIn.modalInactiveExchangeSubTitle':
    'To complete the connection of notifications from VK, follow the link and send the following code to the bot: {code}',
  'components.components.molecules.QuickSingnIn.modalInactiveExchangeTitle':
    'It is necessary to complete the connection of VK notifications',

  'components.molecules.Footer.aboutUs': 'About Us',
  'components.molecules.Footer.contacts': 'Contacts',
  'components.molecules.Footer.cookies': 'Cookies Policy',
  'components.molecules.Footer.docs': 'Reference section',
  'components.molecules.Footer.forUsers': 'For Users',
  'components.molecules.Footer.policies': 'Policies',
  'components.molecules.Footer.privacy': 'Privacy Policy',
  'components.molecules.Footer.service': 'Terms of Service',
  'components.molecules.Footer.socialMedia': 'Social Media',

  'components.molecules.List.noData': 'No Data',

  'components.molecules.Messengers.title': 'Notification Addressing',

  'components.molecules.NoLogs.title': 'No logs yet',

  'components.molecules.PasswordForgotSent.button': 'Re-enter your email',
  'components.molecules.PasswordForgotSent.subTitle':
    'Password recovery link was sent to the email you provided {email}',
  'components.molecules.PasswordForgotSent.title': 'Check your email!',

  'components.molecules.QuickSignIn.text': 'Quick Sign-in',

  'components.molecules.Table.noData': 'No Data',

  'components.molecules.VkAuth.connectVk': 'Connect VK',

  'components.organisms.AdminTabs.logs.title': 'View logs',
  'components.organisms.AdminTabs.support.title': 'Support',
  'components.organisms.AdminTabs.wiki.add': 'Add article',
  'components.organisms.AdminTabs.wiki.title': 'Wiki',

  'components.organisms.ArticleList.problemTitle': 'Report a problem',

  'components.organisms.Balance.currency': '{value} USDT',

  'components.organisms.BotCharacteristics.add': 'Create bot',
  'components.organisms.BotCharacteristics.title': 'Bot characteristics',

  'components.organisms.BotState.title': 'Bot state',
  'components.organisms.BotState.total': 'Total',

  'components.organisms.BotTable.algorithm': 'Algorithm',
  'components.organisms.BotTable.countOrders': 'Count of Orders',
  'components.organisms.BotTable.created': 'Created',
  'components.organisms.BotTable.deposit': 'Deposit',
  'components.organisms.BotTable.errors': 'Errors',
  'components.organisms.BotTable.marketplace': 'Marketplace',
  'components.organisms.BotTable.modalInactiveButton': 'Inactive',
  'components.organisms.BotTable.modalInactiveSubTitle':
    'Are you sure you want to make this bot inactive? This action will cancel all orders of the selected bot',
  'components.organisms.BotTable.modalInactiveTitle': 'Make the bot inactive',
  'components.organisms.BotTable.name': 'Bot name',
  'components.organisms.BotTable.priceOverlap': 'Price overlap',
  'components.organisms.BotTable.profit': 'Profit',
  'components.organisms.BotTable.started': 'Started',
  'components.organisms.BotTable.status': 'Status',
  'components.organisms.BotTable.totalIncome': 'Total income',
  'components.organisms.BotTable.tradingPair': 'Trading pair',

  'components.organisms.BotTabs.edit': 'Edit',
  'components.organisms.BotTabs.info': 'Characteristic',
  'components.organisms.BotTabs.statistics': 'Statistics',

  'components.organisms.BotsTable.alertMessage':
    'Do not delete orders on the exchange side! In case you want to delete the order grid - stop the corresponding bot.',
  'components.organisms.BotsTable.buyButton': 'Buy',
  'components.organisms.BotsTable.disableEdit_tooltip':
    'Editing is prohibited due to incorrect work of the bot. Make inactive the bot and try again.',
  'components.organisms.BotsTable.editButton': 'Edit',
  'components.organisms.BotsTable.error_tooltip': 'Errors: {number}',
  'components.organisms.BotsTable.makeActive': 'Works',
  'components.organisms.BotsTable.viewButton': 'View statistic',

  'components.organisms.Chat.messagePlaceholder': 'Write message',
  'components.organisms.Chat.sender': 'Sender',
  'components.organisms.Chat.status': 'Status',
  'components.organisms.Chat.subject': 'Subject message',

  'components.organisms.ChatMessageGroup.attachment': 'Attachment {counter}',

  'components.organisms.ChatsTable.authorEmail': 'Author Email',
  'components.organisms.ChatsTable.created': 'Request from',
  'components.organisms.ChatsTable.noData': 'No Data',
  'components.organisms.ChatsTable.status': 'Status',
  'components.organisms.ChatsTable.theme': 'Theme',

  'components.organisms.ExportReports.allTime': 'all time',
  'components.organisms.ExportReports.perDay': 'per day',
  'components.organisms.ExportReports.perMonth': 'per month',
  'components.organisms.ExportReports.perWeek': 'per week',

  'components.organisms.Landing.app': 'My account',
  'components.organisms.Landing.footerBot':
    '24/7 automated bot for cryptocurrency trading',
  'components.organisms.Landing.footerPresets': 'Ready presets',
  'components.organisms.Landing.footerSettings': 'Flexible bot settings',
  'components.organisms.Landing.footerTrade':
    'Simultaneous trading on several exchanges',
  'components.organisms.Landing.goToAccountButton': 'Go to account',
  'components.organisms.Landing.heroSubtitle':
    'The best assistant for trading on the best cryptocurrency exchanges. Trading has never been so easy.',
  'components.organisms.Landing.heroTitle': 'Designed to automate trading',
  'components.organisms.Landing.reasonsAutomationText':
    '24/7 trading without your constant participation in the processes',
  'components.organisms.Landing.reasonsAutomationTitle': 'Automation',
  'components.organisms.Landing.reasonsButtonText': 'Start trading',
  'components.organisms.Landing.reasonsFunctionalityText':
    'Lots of flexible settings and ready-made presets for convenience',
  'components.organisms.Landing.reasonsFunctionalityTitle': 'Functionality',
  'components.organisms.Landing.reasonsMultitaskingText':
    'Simultaneous use of bots on several exchanges',
  'components.organisms.Landing.reasonsMultitaskingTitle': 'Multitasking',
  'components.organisms.Landing.reasonsTitle': 'Why choose 7 Sense Trade?',
  'components.organisms.Landing.signIn': 'Sign In',
  'components.organisms.Landing.signInButton': 'Sign In',
  'components.organisms.Landing.signUp': 'Sign Up',
  'components.organisms.Landing.signUpButton': 'Sign Up',
  'components.organisms.Landing.social': '24/7 Support',
  'components.organisms.Landing.subtitle':
    'Your indispensable assistant for easy trading on the best cryptocurrency exchanges',

  'components.organisms.LogsTable.endpoint': 'Endpoint',
  'components.organisms.LogsTable.ip': 'IP',
  'components.organisms.LogsTable.method': 'Method',
  'components.organisms.LogsTable.request': 'Request',
  'components.organisms.LogsTable.tableDate': 'Date',
  'components.organisms.LogsTable.userId': 'User ID',

  'components.organisms.MarketplaceBot.buyButton': 'Buy',
  'components.organisms.MarketplaceBot.disableEdit_tooltip':
    'Editing is forbidden because the status is error, disable the bot and editing will become available.',
  'components.organisms.MarketplaceBot.editButton': 'Edit',
  'components.organisms.MarketplaceBot.statisticsButton': 'Statistics',

  'components.organisms.MarketplaceBotStatistics.created': 'Created/completion',
  'components.organisms.MarketplaceBotStatistics.cycleCancellation':
    'Reason for cycle cancellation',
  'components.organisms.MarketplaceBotStatistics.cycleStatus': 'Cycle status',
  'components.organisms.MarketplaceBotStatistics.deposit': 'Deposit',
  'components.organisms.MarketplaceBotStatistics.exchangeRate': 'Exchange rate',
  'components.organisms.MarketplaceBotStatistics.mainPair': 'Main pair',
  'components.organisms.MarketplaceBotStatistics.noData': 'No Data',
  'components.organisms.MarketplaceBotStatistics.orderStatus': 'Order status',
  'components.organisms.MarketplaceBotStatistics.period': 'Period',
  'components.organisms.MarketplaceBotStatistics.profit': 'Profit in BTC',
  'components.organisms.MarketplaceBotStatistics.secondaryPair':
    'Secondary pair',
  'components.organisms.MarketplaceBotStatistics.tradingPair': 'Trading pair',

  'components.organisms.NoBots.button.buy': 'Add',
  'components.organisms.NoBots.button.create': 'Create a bot',
  'components.organisms.NoBots.title': 'No bots yet',

  'components.organisms.NotificationsTable.changeEmail': 'Change login',
  'components.organisms.NotificationsTable.changePassword': 'Change password',
  'components.organisms.NotificationsTable.changePhone': 'Change phone',
  'components.organisms.NotificationsTable.email': 'E-mail',
  'components.organisms.NotificationsTable.security': 'Security',
  'components.organisms.NotificationsTable.sms': 'SMS',
  'components.organisms.NotificationsTable.subtitle': 'Types of notifications',
  'components.organisms.NotificationsTable.system': 'Systems',
  'components.organisms.NotificationsTable.telegram': 'Telegram',
  'components.organisms.NotificationsTable.vk': 'VK',
  'components.organisms.NotificationsTable.whatsApp': 'WhatsApp',

  'components.organisms.ProfitChart.radio.month': 'Month',
  'components.organisms.ProfitChart.radio.week': 'Week',
  'components.organisms.ProfitChart.radio.year': 'Year',
  'components.organisms.ProfitChart.title': 'Profit',
  'components.organisms.ProfitChart.total': 'Total',

  'components.organisms.SignOut.button': 'Log out',

  'components.organisms.WikiTabs.edit': 'Edit',
  'components.organisms.WikiTabs.view': 'View',

  'components.organisms.forms.AddWallet.amount': 'Quantity',
  'components.organisms.forms.AddWallet.currency': 'Currency',
  'components.organisms.forms.AddWallet.saveButton': 'Confirm',
  'components.organisms.forms.CreateArticleForm.deleteButton': 'Delete',
  'components.organisms.forms.CreateArticleForm.description':
    'Short description',
  'components.organisms.forms.CreateArticleForm.saveButton': 'Save',
  'components.organisms.forms.CreateArticleForm.text': 'Article text',
  'components.organisms.forms.CreateArticleForm.title': 'Title',
  'components.organisms.forms.CreateBotForm.botName': 'Bot name',
  'components.organisms.forms.CreateBotForm.countOrders': 'Count of Orders',
  'components.organisms.forms.CreateBotForm.deposit': 'Deposit',
  'components.organisms.forms.CreateBotForm.exchange': 'Exchange',
  'components.organisms.forms.CreateBotForm.martingale':
    'Martingale Percentage',
  'components.organisms.forms.CreateBotForm.overlap': 'Price Overlap',
  'components.organisms.forms.CreateBotForm.saveButton': 'Save',
  'components.organisms.forms.CreateBotForm.status': 'Status',
  'components.organisms.forms.CreateBotForm.stopLoss': 'Stop loss',
  'components.organisms.forms.CreateBotForm.takeProfit': 'Take Profit',
  'components.organisms.forms.CreateBotForm.tradingPair': 'Trading Pair',
  'components.organisms.forms.DeleteAccountForm.button': 'Confirm',
  'components.organisms.forms.DeleteAccountForm.info':
    'Your account will be permanently deleted after 30 days',
  'components.organisms.forms.DeleteAccountForm.placeholder': 'Enter password',
  'components.organisms.forms.EditBotForm.stopLoss': 'Stop Loss',
  'components.organisms.forms.EmailChangeForm.button': 'Send',
  'components.organisms.forms.EmailChangeForm.email': 'Email',
  'components.organisms.forms.ForgotPasswordChangeForm.button': 'Save',
  'components.organisms.forms.ForgotPasswordChangeForm.newPassword':
    'New password',
  'components.organisms.forms.ForgotPasswordChangeForm.newPassword2':
    'Confirm password',
  'components.organisms.forms.ForgotPasswordForm.description':
    'We will send a link to reset your password to your email address',
  'components.organisms.forms.MessengerForm.button': 'Submit',
  'components.organisms.forms.MessengerForm.connect': 'Connect',
  'components.organisms.forms.MessengerForm.disconnect': 'Disconnect',
  'components.organisms.forms.MessengerForm.telegram':
    'Enter your Telegram number',
  'components.organisms.forms.MessengerForm.vk': 'Vk',
  'components.organisms.forms.MessengerForm.whatsApp':
    'Enter your WhatsApp number',
  'components.organisms.forms.MessengerForm.whatsapp': 'WhatsApp',
  'components.organisms.forms.PasswordChangeForm.button': 'Send',
  'components.organisms.forms.PasswordChangeForm.newPassword': 'New password',
  'components.organisms.forms.PasswordChangeForm.password': 'Current password',
  'components.organisms.forms.PasswordForgotForm.button': 'Send',
  'components.organisms.forms.PasswordForgotForm.email': 'Email address',
  'components.organisms.forms.PhoneChangeForm.button': 'Sent',
  'components.organisms.forms.PhoneChangeForm.phone': 'Phone number',
  'components.organisms.forms.SignInEmailForm.button': 'Sign In',
  'components.organisms.forms.SignInEmailForm.email': 'Email address',
  'components.organisms.forms.SignInEmailForm.forgotPassword':
    'Forgot your password?',
  'components.organisms.forms.SignInEmailForm.password': 'Password',
  'components.organisms.forms.SignUpEmailForm.button': 'Sign Up',
  'components.organisms.forms.SignUpEmailForm.checkbox':
    'By registering, you agree to:',
  'components.organisms.forms.SignUpEmailForm.cookies': 'Cookies Policy',
  'components.organisms.forms.SignUpEmailForm.email': 'Email address',
  'components.organisms.forms.SignUpEmailForm.password': 'Password',
  'components.organisms.forms.SignUpEmailForm.privacy': 'Privacy Policy',
  'components.organisms.forms.SignUpEmailForm.service': 'Terms of Service',
  'components.organisms.forms.SignUpEmailForm.signInButton': 'Sign In',
  'components.organisms.forms.VerifyPhoneNumberForm.button': 'Submit',
  'components.organisms.forms.VerifyPhoneNumberForm.code': 'Enter SMS-code',

  'components.routes.modals.AboutUs.title': 'About Us',
  'components.routes.modals.AddWallet.title': 'Online cash desk',
  'components.routes.modals.AdminWikiAdd.title': 'Add article',
  'components.routes.modals.AdminWikiArticle.title': 'Articles',
  'components.routes.modals.AdminWikiEdit.title': 'Edit article',
  'components.routes.modals.CabinetDelete.title': 'Delete account',
  'components.routes.modals.CabinetSignOut.title': 'Log out of your account',
  'components.routes.modals.Contacts.title': 'Contacts',
  'components.routes.modals.ContactsEmail.title': 'Change email',
  'components.routes.modals.ContactsMessengers.title': 'Change Messenger',
  'components.routes.modals.ContactsPassword.title': 'Change password',
  'components.routes.modals.ContactsPhone.title': 'Change phone number',
  'components.routes.modals.Cookies.point1':
    "The 7sensetrade website uses cookies to make personalized features available and to analyze the use of the 7sensetrade website. A cookie is a small file that stores certain information about the user's access device (PC, tablet, smartphone, etc.). When the device accesses the 7sensetrade website, the server receives cookies. The server can evaluate the information stored in the cookie using various methods. Cookies allow you to personalize advertising in accordance with the collected user behavior and by collecting statistical data on the use of the website. Users can accept or disable cookies in their browser settings. However, if the user disables cookies, he or she will not be able to use all the interactive features of the 7sensetrade website.",
  'components.routes.modals.Cookies.point2':
    'Moreover, each individual web page uses log files; for each page display, access data is stored in the server log. The saved data contains the following details:',
  'components.routes.modals.Cookies.point2subpoint1':
    "Date, time, file access, status, the request that the user's browser sent to the server, the amount of data transmitted and the web page from which the user accessed the requested page (referrer).",
  'components.routes.modals.Cookies.point2subpoint2':
    "Information about the product and version of the browser used, the user's operating system and the user's country of origin.",
  'components.routes.modals.Cookies.point3':
    "7sensetrade uses log data only in an anonymous form (i.e. without any distribution or indication of the user's identity) for statistical analysis; for example, to find out on which days and at what time 7sensetrade web pages are most popular and how much data is generated on 7sensetrade web pages. In addition, log files allow 7sensetrade to detect possible errors, such as erroneous links or program errors. Thus, 7sensetrade can use log files to further develop 7sensetrade web pages. 7sensetrade never associates the use and displays of pages that are stored in the server log with individuals. 7sensetrade reserves the right to use data from log files if it is suspected that users are using 7sensetrade websites and/or services in an illegal or non-contractual manner.",
  'components.routes.modals.Cookies.title': 'Cookies Policy',
  'components.routes.modals.Docs.title': 'Reference section',
  'components.routes.modals.DocsArticle.title': 'Reference section',
  'components.routes.modals.ExchangeConnect.modalInactiveExchangeButton':
    'Connect',
  'components.routes.modals.ExchangeConnect.subTitle':
    'Exchange is not connected',
  'components.routes.modals.ExchangeConnect.title': 'Attention!',
  'components.routes.modals.Exchanges.APIKey': 'API key',
  'components.routes.modals.Exchanges.save': 'Save',
  'components.routes.modals.Exchanges.secretPart': 'Secret part of API key',
  'components.routes.modals.Exchanges.title': 'Exchange Settings',
  'components.routes.modals.ForgotMe.button': "It's clear",
  'components.routes.modals.ForgotMe.info':
    'After deleting your account to your email notification will be sent',
  'components.routes.modals.ForgotMe.subTitle':
    'Your account will be permanently deletedin 30 days',
  'components.routes.modals.ForgotMe.title': 'Account deleted',
  'components.routes.modals.ForgotPassword.title': 'Password recovery',
  'components.routes.modals.ForgotPasswordChange.title': 'Change password',
  'components.routes.modals.Language.title': 'Language selection',
  'components.routes.modals.MarketplaceAdd.title': 'Create a bot',
  'components.routes.modals.MarketplaceBot.title': 'Bot Characteristics',
  'components.routes.modals.MarketplaceBotEdit.title': 'Edit',
  'components.routes.modals.MarketplaceBotStatistics.title': 'Bot Statistics',
  'components.routes.modals.Notifications.delete': 'Delete everything',
  'components.routes.modals.Notifications.read': 'Make read',
  'components.routes.modals.Notifications.title': 'Notifications',
  'components.routes.modals.Privacy.h3_1':
    'What personal information do we collect from people?',
  'components.routes.modals.Privacy.h3_2': 'When do we collect information?',
  'components.routes.modals.Privacy.h3_3': 'How do we use your information?',
  'components.routes.modals.Privacy.h3_4':
    'How do we protect your information?',
  'components.routes.modals.Privacy.h3_5': 'Your choice:',
  'components.routes.modals.Privacy.point1_1':
    'When registering on our site, you may be asked to enter your full name, email address, location time zone or other data that will help you in your user experience.',
  'components.routes.modals.Privacy.point1_2':
    'Information that we collect automatically. When you access or use our Services, we automatically collect information about you, including log information.',
  'components.routes.modals.Privacy.point5_1_text':
    'You have the right to request, change or delete your personal data that is stored in our systems and on our website. You can independently change or delete your personal data using the appropriate functions at any time by logging into your account on our website or by contacting our support service for help. If you want to delete your personal data, please note that we may retain certain information in accordance with the requirements of the law. We may also keep cached or archived copies of information about you for a certain period of time.',
  'components.routes.modals.Privacy.point5_1_title': 'Account Information.',
  'components.routes.modals.Privacy.point5_2_text':
    'Most web browsers are configured to accept cookies by default. If you wish, you can usually configure your browser to delete or reject browser cookies, but please note that if you decide to delete or reject cookies, this may affect the availability and functionality of the Services.',
  'components.routes.modals.Privacy.point5_2_title': 'Cookie.',
  'components.routes.modals.Privacy.point5_3_text':
    'You can opt out of receiving promotional emails or text messages by following the instructions given in these emails or text messages. If you opt out, we may still send you non-promotional messages, for example, about your account or about our current business relationship.',
  'components.routes.modals.Privacy.point5_3_title':
    'Advertising communications.',
  'components.routes.modals.Privacy.text1':
    "7sensetrade collects, uses and stores users' personal data in accordance with applicable data protection legislation. Personal data – any information related to an identified or identifiable individual. Below 7sensetrade informs all users of the website about the type, scope and purpose of the collection and use of personal data. The terms defined in the 7sensetrade Terms of Service have the same meaning here.",
  'components.routes.modals.Privacy.text2':
    'The policy applies to all visitors to the 7sensetrade website.',
  'components.routes.modals.Privacy.text2_1':
    'We collect information from you when you log in, register, or enter information on our website.',
  'components.routes.modals.Privacy.text3_1':
    'We may use the information we receive from you when you register, top up your balance, subscribe to our newsletter, respond to surveys or marketing messages, browse the website or use some other site features to process your transactions quickly. If you give us your social media contact details, we can use them to add you to our official social media groups, such as Telegram, VK and others.',
  'components.routes.modals.Privacy.text4_1':
    'Our website is regularly scanned for security breaches and known vulnerabilities in order to make visiting our website as safe as possible. We use a regular malware scan. Your personal information is stored in secure networks and is accessible only to a limited number of persons who have special access rights to such systems and are required to maintain the confidentiality of information.',
  'components.routes.modals.Privacy.text4_2':
    'In addition, all confidential/credit information that you provide is encrypted using {text} technology. We apply various security measures when a user enters, submits or accesses their information to ensure the security of your personal information. We do not share any information about you.',
  'components.routes.modals.Privacy.text4_2_ssl': 'Secure Socket Layer (SSL)',
  'components.routes.modals.Privacy.title': 'Privacy Policy',
  'components.routes.modals.SignInEmail.title': 'Sign In',
  'components.routes.modals.SignInEmailForm.signUpButton': 'Sign Up',
  'components.routes.modals.SignUpEmail.title': 'Sign Up',
  'components.routes.modals.Support.button': 'Send',
  'components.routes.modals.Support.email': 'E-mail',
  'components.routes.modals.Support.infoAlertSubtitle':
    'Enter your email to send a message to support.',
  'components.routes.modals.Support.infoAlertTitle': 'You are not authorized!',
  'components.routes.modals.Support.selectPlaceholder': 'Type of problem',
  'components.routes.modals.Support.textAreaPlaceholder': 'Your message',
  'components.routes.modals.Support.title': 'Support',
  'components.routes.modals.SupportMessage.title': 'Ticket #{id}',
  'components.routes.modals.Terms.point1':
    '7sensetrade is not responsible in cases of technical problems on exchanges, theft of funds from exchanges, blocking of accounts on exchanges, incorrect operation of the API of exchanges, bankruptcy of exchanges, erroneous actions of users, as well as strong and prolonged price changes on exchanges.',
  'components.routes.modals.Terms.point10':
    'If the balance of the 7sensetrade account is negative, then the user has 3 days to replenish the balance. After 3 days, the account is blocked. The user cannot create new bots or launch them.',
  'components.routes.modals.Terms.point11':
    'If the account balance remains negative for more than 7 days, the work of bots is interrupted. Tracking of active orders is also suspended.',
  'components.routes.modals.Terms.point12':
    'The balance of the 7sensetrade account can be replenished using BTC, ETH, BNB, USDT. Deposits in BTC, ETH, BNB, USDT will be automatically converted to BTC at the exchange rate at the time of deposit.',
  'components.routes.modals.Terms.point13_days': '3 days',
  'components.routes.modals.Terms.point13_text1':
    'The balance of the 7sensetrade account is not refunded, except in cases of erroneous replenishment.',
  'components.routes.modals.Terms.point13_text2':
    '{title} means replenishing the balance of the 7sensetrade account instead of replenishing the exchange account.',
  'components.routes.modals.Terms.point13_text3':
    'The refund of erroneous deposits is made when submitting a request to the support service, which must be done no later than {days} from the date of replenishment. All requests made later than 3 days from the date of the erroneous deposit will not be received.',
  'components.routes.modals.Terms.point13_text4':
    'When returning erroneous deposits, we charge a 10% manual processing fee of at least $20.',
  'components.routes.modals.Terms.point13_text5':
    'The refund is made in the same currency in which the deposit was made, the user must take this into account when specifying the address and account to which the refund is to be made when contacting the support service.',
  'components.routes.modals.Terms.point13_text6':
    'A refund is only possible for deposits paid to us. Refunds cannot be made by internal transfers from one user to another.',
  'components.routes.modals.Terms.point13_title': 'Erroneous replenishment',
  'components.routes.modals.Terms.point14':
    'Manipulations with the balance of affiliated accounts are prohibited.',
  'components.routes.modals.Terms.point15':
    'Fake sales of bot services and configurations on the marketplace are prohibited.',
  'components.routes.modals.Terms.point16_text1':
    'Rules for placing bot configurations for sale on the marketplace:',
  'components.routes.modals.Terms.point16_text2': 'To add to the market:',
  'components.routes.modals.Terms.point16_text2_point1':
    'The bot description must contain at least 100 characters.',
  'components.routes.modals.Terms.point16_text2_point2':
    'It is necessary to specify a working contact for feedback (email, telegram).',
  'components.routes.modals.Terms.point16_text2_point3':
    'You cannot use the IDs and names of other bots from the trading platform in the bot name.',
  'components.routes.modals.Terms.point16_text2_point4':
    'The price of the hosted configuration should not exceed 0.01 BTC.',
  'components.routes.modals.Terms.point16_text3':
    'To appear on the trading platform',
  'components.routes.modals.Terms.point16_text3_point1':
    'The bot must work without changing the settings for at least 7 days. If you change any bot settings, including the bot name, the bot wallet and the amount in it, the bot will not be displayed in the marketplace until it has worked with the new settings for 7 days.',
  'components.routes.modals.Terms.point16_text3_point2':
    "The bot's deposit (the amount in the bot's wallet) must be equivalent to at least 50 USD.",
  'components.routes.modals.Terms.point2':
    "The service is provided on an 'as is' basis. 7sensetrade does not guarantee a certain profitability when using the service. Bot performance is determined by many factors, such as volatility on exchanges, trading volume and liquidity on exchanges, as well as bot settings. All service settings are available to the user. The user independently makes decisions about the settings and is responsible for losses in case of incorrect or aggressive trading settings.",
  'components.routes.modals.Terms.point3':
    "We do not store or accept user funds, and we do not make any payments (except payments from sales on the marketplace). All funds are stored in user accounts on exchanges. 7sensetrade does not have access to withdraw funds from users' accounts on exchanges. The user and the respective exchanges are responsible for the safety of funds in the accounts of the exchanges. 7sensetrade works with users' funds to place orders on exchanges via the API interface.",
  'components.routes.modals.Terms.point4':
    'A user of our service can have only one 7sensetrade account. It is forbidden to register multiple accounts. If for some reason multiple accounts are needed, then the user should contact the support service. It is forbidden to trade in different 7sensetrade accounts using the same account on the cryptocurrency exchange — this will be considered as having multiple accounts for the user. Accounts with detected violations will be blocked and their funds frozen.',
  'components.routes.modals.Terms.point5':
    'The user of our service agrees that in case of violation of any condition or rule of this User Agreement, his account will be closed and his funds will be frozen.',
  'components.routes.modals.Terms.point6':
    '7sensetrade is not responsible for the information about our service provided on other sites, except {host}',
  'components.routes.modals.Terms.point7_text1': 'Payment for the service',
  'components.routes.modals.Terms.point7_text2':
    '7sensetrade only takes a commission of 20% of the profit you receive when trading with the bot. There is a balance of the 7sensetrade user account for debiting the commission. The balance of the 7sensetrade account is calculated in BTC. The commission is deducted from the balance of the 7sensetrade account every time the bot completes its work cycle and makes a profit (a take profit order is executed on the exchange). If the income is not received in BTC, then it is converted to BTC at the exchange rate at the time of receipt of income.',
  'components.routes.modals.Terms.point8':
    'There is no limit on the number of bots per RevenueBot account.',
  'components.routes.modals.Terms.point9':
    'The 7sensetrade service is available in full with an account balance of 0 or more. The user can create new bots and launch them.',
  'components.routes.modals.Terms.text1':
    "The service is provided to you by 7sensetrade ('7sensetrade', 'bot', 'we', 'us' or 'our') through the website located at {host}. To ensure 'fair work' and a great experience with 7sensetrade, we have created these terms, rules and regulations.",
  'components.routes.modals.Terms.text2':
    '7sensetrade ensures uninterrupted operation of its servers on the Internet 24 hours a day. The user does not need to install any software on his computer to use the service.',
  'components.routes.modals.Terms.text3':
    '7sensetrade reserves the right to make changes, additions and adjustments to these Terms of Use without prior notice.',
  'components.routes.modals.Terms.title': 'Terms of Service',
  'components.routes.modals.VerifyPhoneNumber.title': 'Verify phone code',

  'components.routes.pages.Admin.endpoint.all': 'All endpoints',
  'components.routes.pages.Admin.endpoint.placeholder': 'Endpoint',
  'components.routes.pages.Admin.period': 'Period',
  'components.routes.pages.Admin.title': 'Administration',
  'components.routes.pages.Admin.userId.placeholder': 'User ID',
  'components.routes.pages.AdminSupport.title': 'Support',
  'components.routes.pages.AdminSupportMessage.title': 'Ticket #{id}',
  'components.routes.pages.AdminWiki.title': 'Wiki',
  'components.routes.pages.AdminWikiAdd.title': 'Add article',
  'components.routes.pages.AdminWikiArticle.edit': 'Edit',
  'components.routes.pages.AdminWikiArticle.title': 'Articles',
  'components.routes.pages.App.button': 'Create bot',
  'components.routes.pages.App.title': 'Control panel',
  'components.routes.pages.App.welcomeDescription':
    'To start using the service, create your first bot',
  'components.routes.pages.App.welcomeTitle': 'Welcome!',
  'components.routes.pages.Cabinet.delete': 'Delete account',
  'components.routes.pages.Cabinet.email': 'Email',
  'components.routes.pages.Cabinet.password': 'Password',
  'components.routes.pages.Cabinet.phone': 'Phone number',
  'components.routes.pages.Cabinet.signOut': 'Sign out',
  'components.routes.pages.Cabinet.title': 'My account',
  'components.routes.pages.Cabinet.user': 'Hello, {user}!',
  'components.routes.pages.Cabinet.userId': 'User ID',
  'components.routes.pages.Cabinet2FA.app.description':
    'You can also get a verification code when you sign in to 7sensetrade using a mobile authentication app (such as Google Authenticator or 1Password).',
  'components.routes.pages.Cabinet2FA.app.title': 'Authentication App',
  'components.routes.pages.Cabinet2FA.sms.description':
    'When you sign in to 7sensetrade, we will send a SMS to your mobile phone with a verification code.',
  'components.routes.pages.Cabinet2FA.sms.title': 'SMS',
  'components.routes.pages.Cabinet2FA.text':
    'Protect your account from unauthorized access by enabling another authentication method in addition to your password. You can choose SMS or application for authentication.',
  'components.routes.pages.Cabinet2FA.title': 'Two-factor authentication',
  'components.routes.pages.CabinetExchanges.disconnect': 'Disconnect',
  'components.routes.pages.CabinetExchanges.modalDisconnectButton':
    'Disconnect',
  'components.routes.pages.CabinetExchanges.modalSubTitle':
    'Are you sure you want to disconnect from the exchange? This action will stop all your bots working with this exchange and cancel their orders.',
  'components.routes.pages.CabinetExchanges.modalTitle':
    'Disconnect from the exchange',
  'components.routes.pages.CabinetExchanges.title': 'Exchanges settings',
  'components.routes.pages.CabinetNotifications.alertMessage':
    'You need to connect the exchange in the settings',
  'components.routes.pages.CabinetNotifications.title': 'Notifications',
  'components.routes.pages.ConfirmEmail.title': 'Email confirmation',
  'components.routes.pages.Error404.body': 'Page not found',
  'components.routes.pages.Error404.button': 'To main',
  'components.routes.pages.Error404.title': 'Sorry!',
  'components.routes.pages.Marketplace.add': 'Create a bot',
  'components.routes.pages.Marketplace.tabs.buy': 'Add',
  'components.routes.pages.Marketplace.tabs.sell': 'Sell',
  'components.routes.pages.Marketplace.title': 'Marketplace',
  'components.routes.pages.Root.title': '7SenseTrade',

  'error.endpoint.extensionNotExist': 'File extension not exist',

  'error.endpoint.fileNotExist': 'File not exist',

  'error.endpoint.fileSizeExceeded': 'File size exceeded',

  'error.endpoint.needAddEmail': 'You need to add email to your account',

  'error.endpoint.unsupportedFile': 'Unsupported file extension',

  'src.constants.botStatuses.active': 'Works',
  'src.constants.botStatuses.error': 'Error',
  'src.constants.botStatuses.inactive': 'Inactive',

  'src.constants.exchanges.binanceDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.bitgetDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.bittrexDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.bybitDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.gateDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.huobiDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.kucoinDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',
  'src.constants.exchanges.poloniexDescription':
    'cryptocurrency exchange for trading bitcoin, ethereum and altcoins',

  'src.constants.supportStatuses.completed': 'Completed',
  'src.constants.supportStatuses.pending': 'Pending',

  'src.constants.supportThemes.account': 'My account',
  'src.constants.supportThemes.api': 'API keys',
  'src.constants.supportThemes.bot': 'Bot: settings, creation and management',
  'src.constants.supportThemes.finance': 'Commissions, deposits and wallets',
  'src.constants.supportThemes.marketplace': 'Marketplace',
};
